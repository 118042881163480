import React from 'react';
import { motion } from 'framer-motion';
import { Description, CheckCircle, ArrowForward, RequestQuote, Bolt, AccessTime } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function QuoteLanding() {
  const benefits = [
    {
      title: "Quick Estimates",
      description: "Create professional price quotes in minutes, not hours",
      icon: RequestQuote,
      color: "bg-orange-100 text-orange-600"
    },
    {
      title: "Easy Customization",
      description: "Customize your quotes with just a few clicks",
      icon: Bolt,
      color: "bg-amber-100 text-amber-600"
    },
    {
      title: "Track & Follow Up",
      description: "Keep track of sent quotes and follow up on time",
      icon: AccessTime,
      color: "bg-blue-100 text-blue-600"
    }
  ];

  const howToSteps = [
    {
      title: "Enter Business Details",
      description: "Add your business information and branding",
      image: "/images/quote-step1.svg"
    },
    {
      title: "Add Items & Pricing",
      description: "List your products or services with pricing",
      image: "/images/quote-step2.svg"
    },
    {
      title: "Send & Track",
      description: "Share your quote and track its status",
      image: "/images/quote-step3.svg"
    }
  ];

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Lipana Quote Generator",
    "applicationCategory": "BusinessApplication",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "description": "Create professional price quotes instantly with our easy-to-use quote generator. Perfect for businesses and freelancers.",
  };

  return (
    <>
      <Helmet>
        <title>Free Quote Generator | Create Professional Price Quotes Online</title>
        <meta name="description" content="Generate professional price quotes in minutes. Free online quote generator with automatic calculations, custom branding, and professional templates." />
        <meta name="keywords" content="quote generator, create quote online, free quote maker, professional quote template, price quote" />
        <meta property="og:title" content="Free Quote Generator | Create Professional Price Quotes Online" />
        <meta property="og:description" content="Generate professional price quotes in minutes. Free online quote generator with automatic calculations." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://yourdomain.com/quote" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div className="min-h-screen">
        <Header />

        {/* Hero Section */}
        <section className="relative overflow-hidden bg-gradient-to-br from-orange-900 to-orange-800 pt-32 pb-20 sm:pt-40 sm:pb-32">
          <div className="absolute inset-0">
            <div 
              className="absolute inset-0 bg-[url(/grid.svg)] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]" 
              style={{ 
                backgroundSize: '30px 30px',
                opacity: 0.2,
                maskImage: 'linear-gradient(to bottom, white, transparent)'
              }} 
            />
            <div className="absolute inset-0 bg-gradient-to-t from-orange-900" />
          </div>
          <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <motion.h1 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-4xl font-bold tracking-tight text-white sm:text-6xl"
              >
                Create Professional{' '}
                <span className="text-orange-400">Price Quotes</span>
                <br />in Minutes
              </motion.h1>
              <motion.p 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className="mx-auto mt-6 max-w-2xl text-lg text-orange-100"
              >
                Generate polished, professional quotes instantly.
                No complex software required.
              </motion.p>
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="mt-10 flex justify-center gap-4"
              >
                <Link
                  to="/quote/generate"
                  className="inline-flex items-center px-6 py-3 bg-white text-orange-900 rounded-lg font-semibold hover:bg-orange-50 transition-colors"
                >
                  Create Your Quote
                  <ArrowForward className="ml-2 w-5 h-5" />
                </Link>
                <a
                  href="#how-it-works"
                  className="inline-flex items-center px-6 py-3 bg-orange-800 text-white rounded-lg font-semibold hover:bg-orange-700 transition-colors"
                >
                  Learn More
                </a>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="py-20 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-slate-900">
                Why Use Our Quote Generator?
              </h2>
              <p className="mt-4 text-lg text-slate-600">
                Everything you need to create professional quotes, without the complexity
              </p>
            </div>
            <div className="grid md:grid-cols-3 gap-8">
              {benefits.map((benefit, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="relative p-8 bg-white rounded-2xl shadow-sm hover:shadow-md transition-shadow"
                >
                  <div className={`${benefit.color} w-12 h-12 rounded-xl flex items-center justify-center mb-6`}>
                    <benefit.icon className="w-6 h-6" />
                  </div>
                  <h3 className="text-xl font-semibold text-slate-900 mb-2">{benefit.title}</h3>
                  <p className="text-slate-600">{benefit.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        <section id="how-it-works" className="py-20 bg-slate-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-slate-900">
                How to Create Your Quote
              </h2>
              <p className="mt-4 text-lg text-slate-600">
                Three simple steps to get your professional quote
              </p>
            </div>
            <div className="space-y-20">
              {howToSteps.map((step, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  className={`flex flex-col ${
                    index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
                  } items-center gap-12`}
                >
                  <div className="flex-1">
                    <div className="inline-block px-4 py-2 bg-orange-100 text-orange-700 rounded-full text-sm font-semibold mb-4">
                      Step {index + 1}
                    </div>
                    <h3 className="text-2xl font-bold text-slate-900 mb-4">
                      {step.title}
                    </h3>
                    <p className="text-lg text-slate-600">{step.description}</p>
                  </div>
                  <div className="flex-1">
                    <img
                      src={step.image}
                      alt={step.title}
                      className="rounded-2xl shadow-lg"
                    />
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20 bg-orange-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-bold text-white mb-8">
              Ready to Create Your Professional Quote?
            </h2>
            <p className="text-lg text-orange-100 mb-10 max-w-2xl mx-auto">
              Join thousands of businesses who trust our quote generator
            </p>
            <Link
              to="/quote/generate"
              className="inline-flex items-center px-8 py-4 bg-white text-orange-900 rounded-lg font-semibold hover:bg-orange-50 transition-colors"
            >
              Get Started Now
              <ArrowForward className="ml-2" />
            </Link>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
} 