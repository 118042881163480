import React from 'react';

interface LogoProps {
  className?: string;
}

export default function Logo({ className = "h-8 w-8" }: LogoProps) {
  return (
    <div className={`flex items-end justify-center rounded-lg bg-slate-800 p-1.5 ${className}`}>
      <div className="relative flex items-end gap-[2px]">
        <div className="h-3 w-1 rounded-full bg-white"></div>
        <div className="h-4 w-1 -translate-y-1 rounded-full bg-white"></div>
      </div>
    </div>
  );
} 