import React from 'react';
import { BriefData, ProjectObjective, ProjectStakeholder, ProjectTimeline } from '../types/brief';
import { motion } from 'framer-motion';
import { Add, Delete, CloudUpload, Close } from '@mui/icons-material';

interface BriefFormProps {
  formData: BriefData;
  setFormData: React.Dispatch<React.SetStateAction<BriefData>>;
  onSubmit: (e: React.FormEvent) => void;
}

export default function BriefForm({ formData, setFormData, onSubmit }: BriefFormProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 500000) {
        alert('File size should be less than 500KB');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          logo: reader.result as string
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const removeLogo = () => {
    setFormData(prev => ({
      ...prev,
      logo: undefined
    }));
  };

  // Array handlers for simple string arrays
  const addArrayItem = (field: keyof BriefData, defaultValue: string = '') => {
    setFormData(prev => ({
      ...prev,
      [field]: [...(prev[field] as string[]), defaultValue]
    }));
  };

  const removeArrayItem = (field: keyof BriefData, index: number) => {
    setFormData(prev => ({
      ...prev,
      [field]: (prev[field] as string[]).filter((_, i) => i !== index)
    }));
  };

  const updateArrayItem = (field: keyof BriefData, index: number, value: string) => {
    setFormData(prev => {
      const newArray = [...(prev[field] as string[])];
      newArray[index] = value;
      return {
        ...prev,
        [field]: newArray
      };
    });
  };

  // Scope handlers
  const addScopeItem = (type: 'inScope' | 'outOfScope') => {
    setFormData(prev => ({
      ...prev,
      scope: {
        ...prev.scope,
        [type]: [...prev.scope[type], '']
      }
    }));
  };

  const removeScopeItem = (type: 'inScope' | 'outOfScope', index: number) => {
    setFormData(prev => ({
      ...prev,
      scope: {
        ...prev.scope,
        [type]: prev.scope[type].filter((_, i) => i !== index)
      }
    }));
  };

  const updateScopeItem = (type: 'inScope' | 'outOfScope', index: number, value: string) => {
    setFormData(prev => {
      const newItems = [...prev.scope[type]];
      newItems[index] = value;
      return {
        ...prev,
        scope: {
          ...prev.scope,
          [type]: newItems
        }
      };
    });
  };

  // Requirements handlers
  const addRequirement = (type: keyof typeof formData.requirements) => {
    setFormData(prev => ({
      ...prev,
      requirements: {
        ...prev.requirements,
        [type]: [...prev.requirements[type], '']
      }
    }));
  };

  const removeRequirement = (type: keyof typeof formData.requirements, index: number) => {
    setFormData(prev => ({
      ...prev,
      requirements: {
        ...prev.requirements,
        [type]: prev.requirements[type].filter((_, i) => i !== index)
      }
    }));
  };

  const updateRequirement = (type: keyof typeof formData.requirements, index: number, value: string) => {
    setFormData(prev => {
      const newRequirements = [...prev.requirements[type]];
      newRequirements[index] = value;
      return {
        ...prev,
        requirements: {
          ...prev.requirements,
          [type]: newRequirements
        }
      };
    });
  };

  return (
    <form onSubmit={onSubmit} className="space-y-8">
      {/* Basic Information */}
      <div className="space-y-6">
        <h3 className="text-lg font-semibold text-slate-800">Basic Information</h3>
        
        {/* Logo Upload */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Company Logo (optional)
          </label>
          {formData.logo ? (
            <div className="relative w-40 h-20 mb-2">
              <img
                src={formData.logo}
                alt="Company logo"
                className="w-full h-full object-contain"
              />
              <button
                type="button"
                onClick={removeLogo}
                className="absolute -top-2 -right-2 p-1 bg-red-100 rounded-full text-red-500 hover:bg-red-200"
              >
                <Close className="w-4 h-4" />
              </button>
            </div>
          ) : (
            <div className="flex items-center justify-center w-full">
              <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-slate-500 rounded-lg border-2 border-dashed border-slate-200 cursor-pointer hover:bg-slate-50">
                <CloudUpload className="w-8 h-8 mb-2" />
                <span className="text-sm">Upload logo (max 500KB)</span>
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handleLogoUpload}
                />
              </label>
            </div>
          )}
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Brief Number
            </label>
            <input
              type="text"
              name="briefNumber"
              value={formData.briefNumber}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Date Created
            </label>
            <input
              type="date"
              name="dateCreated"
              value={formData.dateCreated}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Project Title
          </label>
          <input
            type="text"
            name="projectTitle"
            value={formData.projectTitle}
            onChange={handleChange}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Project Background
          </label>
          <textarea
            name="projectBackground"
            value={formData.projectBackground}
            onChange={handleChange}
            rows={4}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            placeholder="Provide background information about the project..."
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Business Context
          </label>
          <textarea
            name="businessContext"
            value={formData.businessContext}
            onChange={handleChange}
            rows={4}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            placeholder="Describe the business context and motivation..."
          />
        </div>
      </div>

      {/* Submit Button */}
      <div className="pt-6">
        <button
          type="submit"
          className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 transition-all duration-200"
        >
          Preview Brief
        </button>
      </div>
    </form>
  );
} 