import React from 'react';
import { ContractData, FormField } from '../types';
import { motion } from 'framer-motion';
import { Add, Delete, CloudUpload, Close } from '@mui/icons-material';

const formFields: FormField[] = [
  {
    id: 'clientName',
    label: 'Client Name',
    type: 'text',
    placeholder: 'Enter client name',
  },
  {
    id: 'freelancerName',
    label: 'Freelancer Name',
    type: 'text',
    placeholder: 'Enter your name',
  },
  {
    id: 'projectScope',
    label: 'Project Scope',
    type: 'textarea',
    placeholder: 'Describe the project scope and objectives',
  },
  {
    id: 'startDate',
    label: 'Start Date',
    type: 'date',
    placeholder: 'Select start date',
  },
  {
    id: 'endDate',
    label: 'End Date',
    type: 'date',
    placeholder: 'Select end date',
  },
  {
    id: 'paymentAmount',
    label: 'Payment Amount',
    type: 'text',
    placeholder: 'Enter total payment amount',
  },
  {
    id: 'paymentSchedule',
    label: 'Payment Schedule',
    type: 'textarea',
    placeholder: 'Describe the payment schedule',
  },
  {
    id: 'deliverables',
    label: 'Deliverables',
    type: 'textarea',
    placeholder: 'List all project deliverables',
  },
];

interface ContractFormProps {
  formData: ContractData;
  setFormData: React.Dispatch<React.SetStateAction<ContractData>>;
  onSubmit: (e: React.FormEvent) => void;
}

export default function ContractForm({ formData, setFormData, onSubmit }: ContractFormProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <form onSubmit={onSubmit} className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {formFields.map((field, index) => (
          <motion.div
            key={field.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className={field.type === 'textarea' ? 'md:col-span-2' : ''}
          >
            <label htmlFor={field.id} className="block text-sm font-medium text-gray-700 mb-2">
              {field.label}
            </label>
            <div className="mt-1">
              {field.type === 'textarea' ? (
                <textarea
                  id={field.id}
                  name={field.id}
                  rows={4}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 min-h-[120px] focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                  placeholder={field.placeholder}
                  value={formData[field.id]}
                  onChange={handleChange}
                />
              ) : (
                <input
                  type={field.type}
                  id={field.id}
                  name={field.id}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                  placeholder={field.placeholder}
                  value={formData[field.id]}
                  onChange={handleChange}
                />
              )}
            </div>
          </motion.div>
        ))}
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.8 }}
        className="pt-6"
      >
        <button
          type="submit"
          className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
        >
          Generate Contract
        </button>
      </motion.div>
    </form>
  );
}