import React from 'react';
import { Link } from 'react-router-dom';
import { Twitter, GitHub } from '@mui/icons-material';
import Logo from './Logo';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white border-t border-slate-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Logo className="h-8 w-8" />
            <span className="font-semibold text-slate-900">Lipana</span>
          </div>
          
          <div className="flex items-center gap-6">
            <Link
              to="/about"
              className="text-sm text-slate-500 hover:text-slate-700"
            >
              About
            </Link>
            <p className="text-sm text-slate-500">
              © {currentYear} All rights reserved.
            </p>
          </div>
          
          <div className="flex items-center gap-6">
            <a
              href="https://twitter.com/lipana"
              target="_blank"
              rel="noopener noreferrer"
              className="text-slate-400 hover:text-slate-500"
            >
              <span className="sr-only">Twitter</span>
              <Twitter className="h-5 w-5" />
            </a>
            <a
              href="https://github.com/lipana"
              target="_blank"
              rel="noopener noreferrer"
              className="text-slate-400 hover:text-slate-500"
            >
              <span className="sr-only">GitHub</span>
              <GitHub className="h-5 w-5" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
} 