import React, { useState } from 'react';
import { ReceiptData } from '../types/receipt';
import { motion, AnimatePresence } from 'framer-motion';
import { FileDownload, RemoveRedEye } from '@mui/icons-material';

interface ReceiptPreviewProps {
  receiptData: ReceiptData;
}

const ReceiptPreview: React.FC<ReceiptPreviewProps> = ({ receiptData }) => {
  const [showFullPreview, setShowFullPreview] = useState(false);

  const generateFullReceipt = () => {
    const statusColor = {
      Paid: 'text-emerald-600',
      Pending: 'text-orange-600',
      Partial: 'text-blue-600'
    }[receiptData.paymentStatus];

    return `
      <div class="receipt-full space-y-8 px-8 py-6">
        <div class="flex justify-between items-start">
          <div>
            <h2 class="text-3xl font-bold text-slate-800">RECEIPT</h2>
            <p class="text-slate-500 mt-1">#${receiptData.receiptNumber}</p>
          </div>
          ${receiptData.logo ? `
            <div class="w-40">
              <img src="${receiptData.logo}" alt="Business logo" style="max-height: 80px; width: auto; object-fit: contain;" />
            </div>
          ` : ''}
        </div>

        <div class="grid grid-cols-2 gap-8 mt-12">
          <div>
            <p class="text-sm font-medium text-slate-500">From</p>
            <h3 class="font-medium text-slate-800 mt-2">${receiptData.businessName}</h3>
            <p class="text-slate-600 mt-1">${receiptData.businessEmail}</p>
            <p class="text-slate-600 mt-1 whitespace-pre-line">${receiptData.businessAddress}</p>
          </div>
          <div>
            <p class="text-sm font-medium text-slate-500">To</p>
            <h3 class="font-medium text-slate-800 mt-2">${receiptData.clientName}</h3>
            <p class="text-slate-600 mt-1">${receiptData.clientEmail}</p>
            <p class="text-slate-600 mt-1 whitespace-pre-line">${receiptData.clientAddress}</p>
          </div>
        </div>

        <div class="grid grid-cols-3 gap-8 mt-8">
          <div>
            <p class="text-sm font-medium text-slate-500">Date</p>
            <p class="text-slate-800 mt-2">${new Date(receiptData.dateIssued).toLocaleDateString()}</p>
          </div>
          <div>
            <p class="text-sm font-medium text-slate-500">Payment Method</p>
            <p class="text-slate-800 mt-2">${receiptData.paymentMethod}</p>
          </div>
          <div>
            <p class="text-sm font-medium text-slate-500">Status</p>
            <p class="${statusColor} font-medium mt-2">${receiptData.paymentStatus}</p>
          </div>
        </div>

        <div class="mt-8">
          <table class="w-full">
            <thead>
              <tr class="border-b border-slate-200">
                <th class="py-3 text-left text-sm font-medium text-slate-500">Description</th>
                <th class="py-3 text-right text-sm font-medium text-slate-500">Quantity</th>
                <th class="py-3 text-right text-sm font-medium text-slate-500">Rate</th>
                <th class="py-3 text-right text-sm font-medium text-slate-500">Amount</th>
              </tr>
            </thead>
            <tbody>
              ${receiptData.items.map(item => `
                <tr class="border-b border-slate-100">
                  <td class="py-4 text-slate-600">${item.description}</td>
                  <td class="py-4 text-right text-slate-600">${item.quantity}</td>
                  <td class="py-4 text-right text-slate-600">$${item.rate.toFixed(2)}</td>
                  <td class="py-4 text-right text-slate-600">$${item.amount.toFixed(2)}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>

          <div class="mt-6 flex justify-end">
            <div class="w-64 space-y-3">
              <div class="flex justify-between text-slate-600">
                <span>Subtotal</span>
                <span>$${receiptData.subtotal.toFixed(2)}</span>
              </div>
              <div class="flex justify-between text-slate-600">
                <span>Tax (10%)</span>
                <span>$${receiptData.tax.toFixed(2)}</span>
              </div>
              <div class="flex justify-between border-t border-slate-200 pt-3 font-medium text-slate-800">
                <span>Total</span>
                <span>$${receiptData.total.toFixed(2)}</span>
              </div>
              <div class="flex justify-between text-slate-600">
                <span>Amount Paid</span>
                <span>$${receiptData.amountPaid.toFixed(2)}</span>
              </div>
              <div class="flex justify-between font-medium ${receiptData.balance > 0 ? 'text-red-600' : 'text-emerald-600'}">
                <span>Balance</span>
                <span>$${receiptData.balance.toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>

        ${receiptData.notes && `
          <div class="mt-8 pt-8 border-t border-slate-200">
            <h3 class="text-sm font-medium text-slate-500">Notes</h3>
            <p class="mt-2 text-slate-600 whitespace-pre-line">${receiptData.notes}</p>
          </div>
        `}

        <div class="mt-16 pt-8 border-t text-center">
          <div class="flex items-center justify-center gap-2 text-slate-400">
            <div class="flex h-4 w-4 items-end justify-center rounded-sm bg-slate-800 p-[4px]">
              <div class="relative flex items-end gap-[1px]">
                <div class="h-[6px] w-[2px] rounded-full bg-white"></div>
                <div class="h-[8px] w-[2px] -translate-y-[1px] rounded-full bg-white"></div>
              </div>
            </div>
            <span class="text-sm">Generated with Lipana</span>
          </div>
        </div>
      </div>
    `;
  };

  const handleDownloadPDF = async () => {
    const html2pdf = (await import('html2pdf.js')).default;
    
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = generateFullReceipt();
    document.body.appendChild(tempContainer);
    
    const opt = {
      margin: 1,
      filename: `receipt-${receiptData.receiptNumber}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    
    await html2pdf().set(opt).from(tempContainer).save();
    document.body.removeChild(tempContainer);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8"
    >
      <div className="flex justify-end mb-6 gap-3">
        <button
          onClick={() => setShowFullPreview(true)}
          className="flex items-center gap-2 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
        >
          <RemoveRedEye className="w-4 h-4" />
          Preview Full Receipt
        </button>
        <button
          onClick={handleDownloadPDF}
          className="flex items-center gap-2 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
        >
          <FileDownload className="w-4 h-4" />
          Download PDF
        </button>
      </div>

      <div 
        id="receipt-preview"
        className="space-y-8"
        dangerouslySetInnerHTML={{ __html: generateFullReceipt() }}
      />

      <AnimatePresence>
        {showFullPreview && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black"
              onClick={() => setShowFullPreview(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="fixed inset-4 bg-white rounded-xl overflow-auto p-8 max-w-4xl mx-auto"
              style={{ top: '2%', height: '96%' }}
            >
              <div className="flex justify-end mb-4">
                <button
                  onClick={() => setShowFullPreview(false)}
                  className="text-gray-500 hover:text-gray-700 rounded-full p-2 hover:bg-gray-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div 
                className="receipt-full space-y-8"
                dangerouslySetInnerHTML={{ __html: generateFullReceipt() }}
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ReceiptPreview; 