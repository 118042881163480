import React from 'react';
import { ProposalData } from '../types/proposal';
import { motion } from 'framer-motion';
import { Add, Delete, CloudUpload, Close } from '@mui/icons-material';

interface ProposalFormProps {
  formData: ProposalData;
  setFormData: React.Dispatch<React.SetStateAction<ProposalData>>;
  onSubmit: (e: React.FormEvent) => void;
}

export default function ProposalForm({ formData, setFormData, onSubmit }: ProposalFormProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 500000) {
        alert('File size should be less than 500KB');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          logo: reader.result as string
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const removeLogo = () => {
    setFormData(prev => ({
      ...prev,
      logo: undefined
    }));
  };

  const addPricingItem = () => {
    setFormData(prev => ({
      ...prev,
      pricing: [...prev.pricing, { description: '', amount: '' }]
    }));
  };

  const removePricingItem = (index: number) => {
    setFormData(prev => {
      const newPricing = prev.pricing.filter((_, i) => i !== index);
      const total = newPricing.reduce((sum, item) => sum + Number(item.amount || 0), 0);
      return {
        ...prev,
        pricing: newPricing,
        totalAmount: total
      };
    });
  };

  const updatePricingItem = (index: number, field: 'description' | 'amount', value: string) => {
    setFormData(prev => {
      const newPricing = [...prev.pricing];
      newPricing[index] = {
        ...newPricing[index],
        [field]: value
      };
      const total = newPricing.reduce((sum, item) => sum + Number(item.amount || 0), 0);
      return {
        ...prev,
        pricing: newPricing,
        totalAmount: total
      };
    });
  };

  return (
    <form onSubmit={onSubmit} className="space-y-8">
      {/* Basic Information */}
      <div className="grid grid-cols-2 gap-6">
        {/* Freelancer Details */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-slate-800">Your Details</h3>
          
          {/* Logo Upload */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Brand Logo (optional)
            </label>
            {formData.logo ? (
              <div className="relative w-40 h-20 mb-2">
                <img
                  src={formData.logo}
                  alt="Brand logo"
                  className="w-full h-full object-contain"
                />
                <button
                  type="button"
                  onClick={removeLogo}
                  className="absolute -top-2 -right-2 p-1 bg-red-100 rounded-full text-red-500 hover:bg-red-200"
                >
                  <Close className="w-4 h-4" />
                </button>
              </div>
            ) : (
              <div className="flex items-center justify-center w-full">
                <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-slate-500 rounded-lg border-2 border-dashed border-slate-200 cursor-pointer hover:bg-slate-50">
                  <CloudUpload className="w-8 h-8 mb-2" />
                  <span className="text-sm">Upload logo (max 500KB)</span>
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={handleLogoUpload}
                  />
                </label>
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Name
            </label>
            <input
              type="text"
              name="freelancerName"
              value={formData.freelancerName}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email
            </label>
            <input
              type="email"
              name="freelancerEmail"
              value={formData.freelancerEmail}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Address
            </label>
            <textarea
              name="freelancerAddress"
              value={formData.freelancerAddress}
              onChange={handleChange}
              rows={3}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>

        {/* Client Details */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-slate-800">Client Details</h3>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Name
            </label>
            <input
              type="text"
              name="clientName"
              value={formData.clientName}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Company
            </label>
            <input
              type="text"
              name="clientCompany"
              value={formData.clientCompany}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email
            </label>
            <input
              type="email"
              name="clientEmail"
              value={formData.clientEmail}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Address
            </label>
            <textarea
              name="clientAddress"
              value={formData.clientAddress}
              onChange={handleChange}
              rows={3}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>
      </div>

      {/* Project Details */}
      <div className="space-y-6">
        <h3 className="text-lg font-semibold text-slate-800">Project Details</h3>
        
        <div className="grid grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Proposal Number
            </label>
            <input
              type="text"
              name="proposalNumber"
              value={formData.proposalNumber}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Date Created
            </label>
            <input
              type="date"
              name="dateCreated"
              value={formData.dateCreated}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Valid Until
            </label>
            <input
              type="date"
              name="validUntil"
              value={formData.validUntil}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Project Title
          </label>
          <input
            type="text"
            name="projectTitle"
            value={formData.projectTitle}
            onChange={handleChange}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Executive Summary
          </label>
          <textarea
            name="executiveSummary"
            value={formData.executiveSummary}
            onChange={handleChange}
            rows={4}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            placeholder="Provide a brief overview of the project..."
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Problem Statement
          </label>
          <textarea
            name="problemStatement"
            value={formData.problemStatement}
            onChange={handleChange}
            rows={4}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            placeholder="Describe the problem or challenge that needs to be addressed..."
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Proposed Solution
          </label>
          <textarea
            name="proposedSolution"
            value={formData.proposedSolution}
            onChange={handleChange}
            rows={4}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            placeholder="Outline your proposed solution..."
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Methodology
          </label>
          <textarea
            name="methodology"
            value={formData.methodology}
            onChange={handleChange}
            rows={4}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            placeholder="Describe your approach and methodology..."
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Deliverables
          </label>
          <textarea
            name="deliverables"
            value={formData.deliverables}
            onChange={handleChange}
            rows={4}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            placeholder="List the project deliverables..."
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Timeline
          </label>
          <textarea
            name="timeline"
            value={formData.timeline}
            onChange={handleChange}
            rows={4}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            placeholder="Outline the project timeline..."
          />
        </div>
      </div>

      {/* Pricing */}
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold text-slate-800">Pricing</h3>
          <button
            type="button"
            onClick={addPricingItem}
            className="flex items-center gap-2 px-4 py-2 text-sm text-slate-800 hover:bg-slate-100 rounded-lg transition-colors"
          >
            <Add className="w-4 h-4" />
            Add Item
          </button>
        </div>
        
        <div className="space-y-4">
          {formData.pricing.map((item, index) => (
            <div key={index} className="grid grid-cols-12 gap-4 items-start">
              <div className="col-span-8">
                <input
                  type="text"
                  placeholder="Description"
                  value={item.description}
                  onChange={(e) => updatePricingItem(index, 'description', e.target.value)}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div className="col-span-3">
                <input
                  type="number"
                  placeholder="Amount"
                  value={item.amount}
                  onChange={(e) => updatePricingItem(index, 'amount', e.target.value)}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div className="col-span-1">
                <button
                  type="button"
                  onClick={() => removePricingItem(index)}
                  className="p-3 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
                >
                  <Delete className="w-4 h-4" />
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end">
          <div className="w-64">
            <div className="flex justify-between text-lg font-semibold">
              <span>Total</span>
              <span>${formData.totalAmount.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Terms */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Terms & Conditions
        </label>
        <textarea
          name="terms"
          value={formData.terms}
          onChange={handleChange}
          rows={4}
          className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          placeholder="Enter the terms and conditions..."
        />
      </div>

      <div className="pt-6">
        <button
          type="submit"
          className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200"
        >
          Preview Proposal
        </button>
      </div>
    </form>
  );
} 