import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import SowForm from '../components/SowForm';
import SowPreview from '../components/SowPreview';
import { SowData } from '../types/sow';

export default function SowGenerator() {
  const [sowData, setSowData] = useState<SowData>({
    sowNumber: `SOW-${new Date().getFullYear()}${String(new Date().getMonth() + 1).padStart(2, '0')}${String(Math.floor(Math.random() * 1000)).padStart(3, '0')}`,
    dateCreated: new Date().toISOString().split('T')[0],
    projectTitle: '',
    projectBackground: '',
    objectives: [
      'Define project scope and requirements',
      'Establish deliverables and timelines',
      'Set clear expectations and success criteria'
    ],
    scope: {
      inScope: [''],
      outOfScope: ['']
    },
    milestones: [{
      title: '',
      description: '',
      dueDate: '',
      deliverables: [''],
      acceptanceCriteria: ['']
    }],
    deliverySchedule: {
      startDate: new Date().toISOString().split('T')[0],
      endDate: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000).toISOString().split('T')[0], // 90 days
      phases: [{
        name: 'Planning',
        duration: '2 weeks',
        description: 'Project planning and requirement gathering'
      }]
    },
    resources: [{
      role: 'Project Manager',
      responsibilities: ['Project oversight', 'Stakeholder communication', 'Risk management'],
      timeCommitment: 'Part-time'
    }],
    assumptions: [
      'Client will provide timely feedback and approvals',
      'Access to necessary systems and resources will be granted',
      'Project requirements will remain stable'
    ],
    constraints: [
      'Budget limitations',
      'Timeline restrictions',
      'Technical dependencies'
    ],
    pricing: {
      type: 'Fixed',
      amount: 0,
      currency: 'USD',
      paymentSchedule: '50% upfront, 50% upon completion'
    },
    acceptance: {
      criteria: [''],
      process: 'Review and approval by project stakeholders',
      timeline: '5 business days for review and feedback'
    },
    governance: {
      reportingStructure: 'Weekly status reports to project stakeholders',
      communicationPlan: 'Regular team meetings and email updates',
      meetingSchedule: 'Weekly progress meetings'
    },
    clientResponsibilities: [
      'Provide timely feedback and approvals',
      'Ensure availability of key stakeholders',
      'Supply necessary content and resources'
    ],
    termsAndConditions: 'Standard terms and conditions apply. Any changes to the scope will require a formal change request.'
  });

  const [showPreview, setShowPreview] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setShowPreview(true);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 to-white p-8">
      <div className="max-w-4xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <Link to="/" className="inline-block mb-8 text-slate-600 hover:text-slate-800">
            ← Back to Home
          </Link>
          <div className="flex items-center justify-center gap-3 mb-4">
            <div className="flex h-12 w-12 items-end justify-center rounded-lg bg-red-600 p-2">
              <div className="relative flex items-end gap-[3px]">
                <div className="h-5 w-2 rounded-full bg-white"></div>
                <div className="h-6 w-2 -translate-y-1 rounded-full bg-white"></div>
              </div>
            </div>
          </div>
          <h1 className="text-4xl font-bold text-slate-800 mb-4">
            Statement of Work Generator
          </h1>
          <p className="text-lg text-slate-600">
            Generate detailed project specifications
          </p>
        </motion.div>

        {showPreview ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className="relative"
          >
            <div className="mb-6 flex justify-between items-center">
              <button
                onClick={() => setShowPreview(false)}
                className="text-slate-800 hover:text-slate-600 font-medium flex items-center gap-2"
              >
                ← Back to Editor
              </button>
              <button
                onClick={() => setShowPreview(false)}
                className="text-slate-600 hover:text-slate-800 rounded-full p-2 hover:bg-slate-100"
                aria-label="Close preview"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="bg-white rounded-xl shadow-xl p-8">
              <SowPreview sowData={sowData} />
            </div>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className="bg-white rounded-xl shadow-xl p-8"
          >
            <SowForm
              formData={sowData}
              setFormData={setSowData}
              onSubmit={handleSubmit}
            />
          </motion.div>
        )}
      </div>
    </div>
  );
} 