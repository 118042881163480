import React from 'react';
import { StatusReportData, ProjectMilestone, ProjectRisk } from '../types/statusReport';
import { motion } from 'framer-motion';
import { Add, Delete, CloudUpload, Close } from '@mui/icons-material';

interface StatusReportFormProps {
  formData: StatusReportData;
  setFormData: React.Dispatch<React.SetStateAction<StatusReportData>>;
  onSubmit: (e: React.FormEvent) => void;
}

export default function StatusReportForm({ formData, setFormData, onSubmit }: StatusReportFormProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 500000) {
        alert('File size should be less than 500KB');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          logo: reader.result as string
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const removeLogo = () => {
    setFormData(prev => ({
      ...prev,
      logo: undefined
    }));
  };

  const addMilestone = () => {
    setFormData(prev => ({
      ...prev,
      milestones: [...prev.milestones, { title: '', status: 'Pending', progress: 0, notes: '' }]
    }));
  };

  const removeMilestone = (index: number) => {
    setFormData(prev => ({
      ...prev,
      milestones: prev.milestones.filter((_, i) => i !== index)
    }));
  };

  const updateMilestone = (index: number, field: keyof ProjectMilestone, value: string | number) => {
    setFormData(prev => {
      const newMilestones = [...prev.milestones];
      newMilestones[index] = {
        ...newMilestones[index],
        [field]: value
      };
      return {
        ...prev,
        milestones: newMilestones
      };
    });
  };

  const addRisk = () => {
    setFormData(prev => ({
      ...prev,
      risks: [...prev.risks, { description: '', impact: 'Low', mitigation: '' }]
    }));
  };

  const removeRisk = (index: number) => {
    setFormData(prev => ({
      ...prev,
      risks: prev.risks.filter((_, i) => i !== index)
    }));
  };

  const updateRisk = (index: number, field: keyof ProjectRisk, value: string) => {
    setFormData(prev => {
      const newRisks = [...prev.risks];
      newRisks[index] = {
        ...newRisks[index],
        [field]: value
      };
      return {
        ...prev,
        risks: newRisks
      };
    });
  };

  const updateBudgetStatus = (field: keyof typeof formData.budgetStatus, value: number) => {
    setFormData(prev => ({
      ...prev,
      budgetStatus: {
        ...prev.budgetStatus,
        [field]: value,
        remaining: field === 'budgeted' ? value - prev.budgetStatus.spent : prev.budgetStatus.budgeted - value
      }
    }));
  };

  const updateScheduleStatus = (field: keyof typeof formData.scheduleStatus, value: boolean | string) => {
    setFormData(prev => ({
      ...prev,
      scheduleStatus: {
        ...prev.scheduleStatus,
        [field]: value
      }
    }));
  };

  return (
    <form onSubmit={onSubmit} className="space-y-8">
      {/* Basic Information */}
      <div className="grid grid-cols-2 gap-6">
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-slate-800">Report Details</h3>
          
          {/* Logo Upload */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Project Logo (optional)
            </label>
            {formData.logo ? (
              <div className="relative w-40 h-20 mb-2">
                <img
                  src={formData.logo}
                  alt="Project logo"
                  className="w-full h-full object-contain"
                />
                <button
                  type="button"
                  onClick={removeLogo}
                  className="absolute -top-2 -right-2 p-1 bg-red-100 rounded-full text-red-500 hover:bg-red-200"
                >
                  <Close className="w-4 h-4" />
                </button>
              </div>
            ) : (
              <div className="flex items-center justify-center w-full">
                <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-slate-500 rounded-lg border-2 border-dashed border-slate-200 cursor-pointer hover:bg-slate-50">
                  <CloudUpload className="w-8 h-8 mb-2" />
                  <span className="text-sm">Upload logo (max 500KB)</span>
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={handleLogoUpload}
                  />
                </label>
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Report Number
            </label>
            <input
              type="text"
              name="reportNumber"
              value={formData.reportNumber}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Date Created
            </label>
            <input
              type="date"
              name="dateCreated"
              value={formData.dateCreated}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-slate-800">Project Information</h3>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Project Title
            </label>
            <input
              type="text"
              name="projectTitle"
              value={formData.projectTitle}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Project Manager
            </label>
            <input
              type="text"
              name="projectManager"
              value={formData.projectManager}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Client Name
            </label>
            <input
              type="text"
              name="clientName"
              value={formData.clientName}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Client Email
            </label>
            <input
              type="email"
              name="clientEmail"
              value={formData.clientEmail}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>
      </div>

      {/* Reporting Period */}
      <div className="grid grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Reporting Period From
          </label>
          <input
            type="date"
            name="reportingPeriod.from"
            value={formData.reportingPeriod.from}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              reportingPeriod: { ...prev.reportingPeriod, from: e.target.value }
            }))}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Reporting Period To
          </label>
          <input
            type="date"
            name="reportingPeriod.to"
            value={formData.reportingPeriod.to}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              reportingPeriod: { ...prev.reportingPeriod, to: e.target.value }
            }))}
            className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          />
        </div>
      </div>

      {/* Executive Summary */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Executive Summary
        </label>
        <textarea
          name="executiveSummary"
          value={formData.executiveSummary}
          onChange={handleChange}
          rows={4}
          className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          placeholder="Provide a brief overview of the project status..."
        />
      </div>

      {/* Accomplishments */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Key Accomplishments
        </label>
        <textarea
          name="accomplishments"
          value={formData.accomplishments}
          onChange={handleChange}
          rows={4}
          className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          placeholder="List key accomplishments during this period..."
        />
      </div>

      {/* Milestones */}
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold text-slate-800">Milestones</h3>
          <button
            type="button"
            onClick={addMilestone}
            className="flex items-center gap-2 px-4 py-2 text-sm text-slate-800 hover:bg-slate-100 rounded-lg transition-colors"
          >
            <Add className="w-4 h-4" />
            Add Milestone
          </button>
        </div>
        
        <div className="space-y-4">
          {formData.milestones.map((milestone, index) => (
            <div key={index} className="grid grid-cols-12 gap-4 items-start">
              <div className="col-span-3">
                <input
                  type="text"
                  placeholder="Milestone Title"
                  value={milestone.title}
                  onChange={(e) => updateMilestone(index, 'title', e.target.value)}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div className="col-span-2">
                <select
                  value={milestone.status}
                  onChange={(e) => updateMilestone(index, 'status', e.target.value as any)}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                >
                  <option value="Completed">Completed</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Pending">Pending</option>
                  <option value="Delayed">Delayed</option>
                </select>
              </div>
              <div className="col-span-2">
                <input
                  type="number"
                  placeholder="Progress %"
                  min="0"
                  max="100"
                  value={milestone.progress}
                  onChange={(e) => updateMilestone(index, 'progress', Number(e.target.value))}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div className="col-span-4">
                <input
                  type="text"
                  placeholder="Notes"
                  value={milestone.notes}
                  onChange={(e) => updateMilestone(index, 'notes', e.target.value)}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div className="col-span-1">
                <button
                  type="button"
                  onClick={() => removeMilestone(index)}
                  className="p-3 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
                >
                  <Delete className="w-4 h-4" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Upcoming Tasks */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Upcoming Tasks
        </label>
        <textarea
          name="upcomingTasks"
          value={formData.upcomingTasks}
          onChange={handleChange}
          rows={4}
          className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          placeholder="List upcoming tasks and activities..."
        />
      </div>

      {/* Risks */}
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold text-slate-800">Risks & Issues</h3>
          <button
            type="button"
            onClick={addRisk}
            className="flex items-center gap-2 px-4 py-2 text-sm text-slate-800 hover:bg-slate-100 rounded-lg transition-colors"
          >
            <Add className="w-4 h-4" />
            Add Risk
          </button>
        </div>
        
        <div className="space-y-4">
          {formData.risks.map((risk, index) => (
            <div key={index} className="grid grid-cols-12 gap-4 items-start">
              <div className="col-span-5">
                <input
                  type="text"
                  placeholder="Risk Description"
                  value={risk.description}
                  onChange={(e) => updateRisk(index, 'description', e.target.value)}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div className="col-span-2">
                <select
                  value={risk.impact}
                  onChange={(e) => updateRisk(index, 'impact', e.target.value as any)}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                >
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>
              <div className="col-span-4">
                <input
                  type="text"
                  placeholder="Mitigation Strategy"
                  value={risk.mitigation}
                  onChange={(e) => updateRisk(index, 'mitigation', e.target.value)}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div className="col-span-1">
                <button
                  type="button"
                  onClick={() => removeRisk(index)}
                  className="p-3 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
                >
                  <Delete className="w-4 h-4" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Budget Status */}
      <div className="space-y-4">
        <h3 className="text-lg font-semibold text-slate-800">Budget Status</h3>
        <div className="grid grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Budgeted Amount
            </label>
            <input
              type="number"
              value={formData.budgetStatus.budgeted}
              onChange={(e) => updateBudgetStatus('budgeted', Number(e.target.value))}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Spent Amount
            </label>
            <input
              type="number"
              value={formData.budgetStatus.spent}
              onChange={(e) => updateBudgetStatus('spent', Number(e.target.value))}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Remaining Amount
            </label>
            <input
              type="number"
              value={formData.budgetStatus.remaining}
              disabled
              className="w-full rounded-lg border border-gray-300 px-4 py-3 bg-gray-50"
            />
          </div>
        </div>
      </div>

      {/* Schedule Status */}
      <div className="space-y-4">
        <h3 className="text-lg font-semibold text-slate-800">Schedule Status</h3>
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="onTrack"
              checked={formData.scheduleStatus.onTrack}
              onChange={(e) => updateScheduleStatus('onTrack', e.target.checked)}
              className="w-4 h-4 text-slate-800 border-gray-300 rounded focus:ring-slate-800"
            />
            <label htmlFor="onTrack" className="text-sm font-medium text-gray-700">
              Project is on track
            </label>
          </div>
          {!formData.scheduleStatus.onTrack && (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Delay Description
                </label>
                <input
                  type="text"
                  value={formData.scheduleStatus.delay || ''}
                  onChange={(e) => updateScheduleStatus('delay', e.target.value)}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Recovery Plan
                </label>
                <textarea
                  value={formData.scheduleStatus.recoveryPlan || ''}
                  onChange={(e) => updateScheduleStatus('recoveryPlan', e.target.value)}
                  rows={3}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
            </>
          )}
        </div>
      </div>

      {/* Next Steps */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Next Steps
        </label>
        <textarea
          name="nextSteps"
          value={formData.nextSteps}
          onChange={handleChange}
          rows={4}
          className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          placeholder="Outline the next steps and action items..."
        />
      </div>

      {/* Additional Notes */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Additional Notes
        </label>
        <textarea
          name="additionalNotes"
          value={formData.additionalNotes}
          onChange={handleChange}
          rows={4}
          className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          placeholder="Any additional notes or comments..."
        />
      </div>

      <div className="pt-6">
        <button
          type="submit"
          className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 transition-all duration-200"
        >
          Preview Status Report
        </button>
      </div>
    </form>
  );
} 