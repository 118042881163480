import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import InvoiceForm from '../components/InvoiceForm';
import InvoicePreview from '../components/InvoicePreview';
import { InvoiceData } from '../types/invoice';

export default function InvoiceGenerator() {
  const [invoiceData, setInvoiceData] = useState<InvoiceData>({
    invoiceNumber: `INV-${new Date().getFullYear()}${String(new Date().getMonth() + 1).padStart(2, '0')}${String(Math.floor(Math.random() * 1000)).padStart(3, '0')}`,
    dateIssued: new Date().toISOString().split('T')[0],
    dateDue: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    clientName: '',
    clientEmail: '',
    clientAddress: '',
    freelancerName: '',
    freelancerEmail: '',
    freelancerAddress: '',
    items: [{ description: '', quantity: 1, rate: 0, amount: 0 }],
    notes: '',
    terms: 'Payment is due within 30 days of invoice date.\nLate payments will incur a 5% monthly fee.',
    subtotal: 0,
    tax: 0,
    total: 0
  });

  const [showPreview, setShowPreview] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setShowPreview(true);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 to-white p-8">
      <div className="max-w-4xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <Link to="/" className="inline-block mb-8 text-slate-600 hover:text-slate-800">
            ← Back to Home
          </Link>
          <div className="flex items-center justify-center gap-3 mb-4">
            <div className="flex h-12 w-12 items-end justify-center rounded-lg bg-emerald-600 p-2">
              <div className="relative flex items-end gap-[3px]">
                <div className="h-5 w-2 rounded-full bg-white"></div>
                <div className="h-6 w-2 -translate-y-1 rounded-full bg-white"></div>
              </div>
            </div>
          </div>
          <h1 className="text-4xl font-bold text-slate-800 mb-4">
            Invoice Generator
          </h1>
          <p className="text-lg text-slate-600">
            Generate professional invoices in seconds
          </p>
        </motion.div>

        {showPreview ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className="relative"
          >
            <div className="mb-6 flex justify-between items-center">
              <button
                onClick={() => setShowPreview(false)}
                className="text-slate-800 hover:text-slate-600 font-medium flex items-center gap-2"
              >
                ← Back to Editor
              </button>
              <button
                onClick={() => setShowPreview(false)}
                className="text-slate-600 hover:text-slate-800 rounded-full p-2 hover:bg-slate-100"
                aria-label="Close preview"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="bg-white rounded-xl shadow-xl p-8">
              <InvoicePreview invoiceData={invoiceData} />
            </div>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className="bg-white rounded-xl shadow-xl p-8"
          >
            <InvoiceForm
              formData={invoiceData}
              setFormData={setInvoiceData}
              onSubmit={handleSubmit}
            />
          </motion.div>
        )}
      </div>
    </div>
  );
} 