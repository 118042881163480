import React, { useState } from 'react';
import { ProposalData } from '../types/proposal';
import { motion, AnimatePresence } from 'framer-motion';
import { FileDownload, RemoveRedEye } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';

interface ProposalPreviewProps {
  proposalData: ProposalData;
}

const ProposalPreview: React.FC<ProposalPreviewProps> = ({ proposalData }) => {
  const [showFullPreview, setShowFullPreview] = useState(false);

  const generateFullProposal = () => {
    return `
      <div class="proposal-full space-y-8 px-8 py-6">
        <div class="flex justify-between items-start">
          <div>
            <h2 class="text-3xl font-bold text-slate-800">Business Proposal</h2>
            <p class="text-slate-500 mt-1">Proposal #${proposalData.proposalNumber}</p>
          </div>
          ${proposalData.logo ? `
            <div class="w-40">
              <img src="${proposalData.logo}" alt="Company logo" style="max-height: 80px; width: auto; object-fit: contain;" />
            </div>
          ` : ''}
        </div>

        <div class="grid grid-cols-2 gap-8 mt-12">
          <div>
            <p class="text-sm font-medium text-slate-500">From</p>
            <h3 class="font-medium text-slate-800 mt-2">${proposalData.freelancerName}</h3>
            <p class="text-slate-600 mt-1">${proposalData.freelancerEmail}</p>
            <p class="text-slate-600 mt-1 whitespace-pre-line">${proposalData.freelancerAddress}</p>
          </div>
          <div>
            <p class="text-sm font-medium text-slate-500">For</p>
            <h3 class="font-medium text-slate-800 mt-2">${proposalData.clientName}</h3>
            <p class="text-slate-600 mt-1">${proposalData.clientCompany}</p>
            <p class="text-slate-600 mt-1">${proposalData.clientEmail}</p>
            <p class="text-slate-600 mt-1 whitespace-pre-line">${proposalData.clientAddress}</p>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-8 mt-8">
          <div>
            <p class="text-sm font-medium text-slate-500">Date Created</p>
            <p class="text-slate-800 mt-2">${new Date(proposalData.dateCreated).toLocaleDateString()}</p>
          </div>
          <div>
            <p class="text-sm font-medium text-slate-500">Valid Until</p>
            <p class="text-slate-800 mt-2">${new Date(proposalData.validUntil).toLocaleDateString()}</p>
          </div>
        </div>

        <div class="mt-12">
          <h3 class="text-2xl font-bold text-slate-800 mb-6">${proposalData.projectTitle}</h3>
          
          <div class="space-y-8">
            <div>
              <h4 class="text-lg font-semibold text-slate-800 mb-3">Executive Summary</h4>
              <div class="text-slate-600 whitespace-pre-line">${proposalData.executiveSummary}</div>
            </div>

            <div>
              <h4 class="text-lg font-semibold text-slate-800 mb-3">Problem Statement</h4>
              <div class="text-slate-600 whitespace-pre-line">${proposalData.problemStatement}</div>
            </div>

            <div>
              <h4 class="text-lg font-semibold text-slate-800 mb-3">Proposed Solution</h4>
              <div class="text-slate-600 whitespace-pre-line">${proposalData.proposedSolution}</div>
            </div>

            <div>
              <h4 class="text-lg font-semibold text-slate-800 mb-3">Methodology</h4>
              <div class="text-slate-600 whitespace-pre-line">${proposalData.methodology}</div>
            </div>

            <div>
              <h4 class="text-lg font-semibold text-slate-800 mb-3">Deliverables</h4>
              <div class="text-slate-600 whitespace-pre-line">${proposalData.deliverables}</div>
            </div>

            <div>
              <h4 class="text-lg font-semibold text-slate-800 mb-3">Timeline</h4>
              <div class="text-slate-600 whitespace-pre-line">${proposalData.timeline}</div>
            </div>

            <div>
              <h4 class="text-lg font-semibold text-slate-800 mb-3">Investment</h4>
              <table class="w-full mt-4">
                <thead>
                  <tr class="border-b border-slate-200">
                    <th class="py-3 text-left text-sm font-medium text-slate-500">Description</th>
                    <th class="py-3 text-right text-sm font-medium text-slate-500">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  ${proposalData.pricing.map(item => `
                    <tr class="border-b border-slate-100">
                      <td class="py-4 text-slate-600">${item.description}</td>
                      <td class="py-4 text-right text-slate-600">$${item.amount}</td>
                    </tr>
                  `).join('')}
                  <tr class="font-medium">
                    <td class="py-4 text-slate-800">Total Investment</td>
                    <td class="py-4 text-right text-slate-800">$${proposalData.totalAmount.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            ${proposalData.terms && `
              <div>
                <h4 class="text-lg font-semibold text-slate-800 mb-3">Terms & Conditions</h4>
                <div class="text-slate-600 whitespace-pre-line">${proposalData.terms}</div>
              </div>
            `}
          </div>
        </div>

        <div class="mt-16 pt-8 border-t">
          <div class="grid grid-cols-2 gap-8">
            <div>
              <p class="text-sm text-slate-500 mb-8">Client Acceptance</p>
              <div class="border-b border-slate-800 w-48"></div>
              <p class="text-sm text-slate-600 mt-2">${proposalData.clientName}</p>
              <p class="text-sm text-slate-500 mt-1">Date: _________________</p>
            </div>
            <div>
              <p class="text-sm text-slate-500 mb-8">Proposed By</p>
              <div class="border-b border-slate-800 w-48"></div>
              <p class="text-sm text-slate-600 mt-2">${proposalData.freelancerName}</p>
              <p class="text-sm text-slate-500 mt-1">Date: ${new Date(proposalData.dateCreated).toLocaleDateString()}</p>
            </div>
          </div>
        </div>

        <div class="mt-16 pt-8 border-t text-center">
          <div class="flex items-center justify-center gap-2 text-slate-400">
            <div class="flex h-4 w-4 items-end justify-center rounded-sm bg-slate-800 p-[4px]">
              <div class="relative flex items-end gap-[1px]">
                <div class="h-[6px] w-[2px] rounded-full bg-white"></div>
                <div class="h-[8px] w-[2px] -translate-y-[1px] rounded-full bg-white"></div>
              </div>
            </div>
            <span class="text-sm">Generated with Lipana</span>
          </div>
        </div>
      </div>
    `;
  };

  const handleDownloadPDF = async () => {
    const html2pdf = (await import('html2pdf.js')).default;
    
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = generateFullProposal();
    document.body.appendChild(tempContainer);
    
    const opt = {
      margin: 1,
      filename: `proposal-${proposalData.proposalNumber}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    
    await html2pdf().set(opt).from(tempContainer).save();
    document.body.removeChild(tempContainer);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8"
    >
      <div className="flex justify-end mb-6 gap-3">
        <button
          onClick={() => setShowFullPreview(true)}
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <RemoveRedEye className="w-4 h-4" />
          Preview Full Proposal
        </button>
        <button
          onClick={handleDownloadPDF}
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <FileDownload className="w-4 h-4" />
          Download PDF
        </button>
      </div>

      <div 
        id="proposal-preview"
        className="space-y-8"
        dangerouslySetInnerHTML={{ __html: generateFullProposal() }}
      />

      <AnimatePresence>
        {showFullPreview && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black"
              onClick={() => setShowFullPreview(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="fixed inset-4 bg-white rounded-xl overflow-auto p-8 max-w-4xl mx-auto"
              style={{ top: '2%', height: '96%' }}
            >
              <div className="flex justify-end mb-4">
                <button
                  onClick={() => setShowFullPreview(false)}
                  className="text-gray-500 hover:text-gray-700 rounded-full p-2 hover:bg-gray-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div 
                className="proposal-full space-y-8"
                dangerouslySetInnerHTML={{ __html: generateFullProposal() }}
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ProposalPreview; 