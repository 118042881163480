import React, { useState } from 'react';
import { SowData } from '../types/sow';
import { motion, AnimatePresence } from 'framer-motion';
import { FileDownload, RemoveRedEye } from '@mui/icons-material';

interface SowPreviewProps {
  sowData: SowData;
}

const SowPreview: React.FC<SowPreviewProps> = ({ sowData }) => {
  const [showFullPreview, setShowFullPreview] = useState(false);

  const generateFullSow = () => {
    return `
      <div class="sow-full space-y-8 px-8 py-6">
        <div class="flex justify-between items-start">
          <div>
            <h2 class="text-3xl font-bold text-slate-800">STATEMENT OF WORK</h2>
            <p class="text-slate-500 mt-1">#${sowData.sowNumber}</p>
          </div>
          ${sowData.logo ? `
            <div class="w-40">
              <img src="${sowData.logo}" alt="Company logo" style="max-height: 80px; width: auto; object-fit: contain;" />
            </div>
          ` : ''}
        </div>

        <div class="mt-8">
          <h3 class="text-2xl font-bold text-slate-800">${sowData.projectTitle}</h3>
          <p class="text-slate-500 mt-2">Created on ${new Date(sowData.dateCreated).toLocaleDateString()}</p>
        </div>

        <div class="space-y-8 mt-12">
          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Project Background</h3>
            <p class="text-slate-600 whitespace-pre-line">${sowData.projectBackground}</p>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Project Objectives</h3>
            <ul class="list-disc pl-6 space-y-2 text-slate-600">
              ${sowData.objectives.map(objective => `
                <li>${objective}</li>
              `).join('')}
            </ul>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Project Scope</h3>
            <div class="grid grid-cols-2 gap-8">
              <div>
                <h4 class="font-medium text-slate-800 mb-3">In Scope</h4>
                <ul class="list-disc pl-6 space-y-2 text-slate-600">
                  ${sowData.scope.inScope.map(item => `
                    <li>${item}</li>
                  `).join('')}
                </ul>
              </div>
              <div>
                <h4 class="font-medium text-slate-800 mb-3">Out of Scope</h4>
                <ul class="list-disc pl-6 space-y-2 text-slate-600">
                  ${sowData.scope.outOfScope.map(item => `
                    <li>${item}</li>
                  `).join('')}
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Project Milestones</h3>
            <div class="space-y-6">
              ${sowData.milestones.map((milestone, index) => `
                <div class="border border-slate-200 rounded-lg p-6">
                  <div class="flex justify-between items-start mb-4">
                    <div>
                      <h4 class="font-medium text-slate-800">${milestone.title}</h4>
                      <p class="text-slate-500 mt-1">Due: ${new Date(milestone.dueDate).toLocaleDateString()}</p>
                    </div>
                  </div>
                  <p class="text-slate-600 mb-4">${milestone.description}</p>
                  <div class="grid grid-cols-2 gap-6">
                    <div>
                      <h5 class="text-sm font-medium text-slate-700 mb-2">Deliverables</h5>
                      <ul class="list-disc pl-6 space-y-1 text-slate-600">
                        ${milestone.deliverables.map(deliverable => `
                          <li>${deliverable}</li>
                        `).join('')}
                      </ul>
                    </div>
                    <div>
                      <h5 class="text-sm font-medium text-slate-700 mb-2">Acceptance Criteria</h5>
                      <ul class="list-disc pl-6 space-y-1 text-slate-600">
                        ${milestone.acceptanceCriteria.map(criterion => `
                          <li>${criterion}</li>
                        `).join('')}
                      </ul>
                    </div>
                  </div>
                </div>
              `).join('')}
            </div>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Delivery Schedule</h3>
            <div class="space-y-4">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <p class="text-sm text-slate-500">Start Date</p>
                  <p class="text-slate-800 mt-1">${new Date(sowData.deliverySchedule.startDate).toLocaleDateString()}</p>
                </div>
                <div>
                  <p class="text-sm text-slate-500">End Date</p>
                  <p class="text-slate-800 mt-1">${new Date(sowData.deliverySchedule.endDate).toLocaleDateString()}</p>
                </div>
              </div>
              <div class="mt-4">
                <h4 class="font-medium text-slate-800 mb-3">Project Phases</h4>
                <div class="space-y-4">
                  ${sowData.deliverySchedule.phases.map(phase => `
                    <div class="border border-slate-200 rounded-lg p-4">
                      <div class="flex justify-between items-start">
                        <div>
                          <h5 class="font-medium text-slate-800">${phase.name}</h5>
                          <p class="text-slate-500 mt-1">${phase.duration}</p>
                        </div>
                      </div>
                      <p class="text-slate-600 mt-2">${phase.description}</p>
                    </div>
                  `).join('')}
                </div>
              </div>
            </div>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Resource Allocation</h3>
            <div class="space-y-6">
              ${sowData.resources.map(resource => `
                <div class="border border-slate-200 rounded-lg p-6">
                  <div class="flex justify-between items-start mb-4">
                    <div>
                      <h4 class="font-medium text-slate-800">${resource.role}</h4>
                      <p class="text-slate-500 mt-1">${resource.timeCommitment}</p>
                    </div>
                  </div>
                  <div>
                    <h5 class="text-sm font-medium text-slate-700 mb-2">Responsibilities</h5>
                    <ul class="list-disc pl-6 space-y-1 text-slate-600">
                      ${resource.responsibilities.map(responsibility => `
                        <li>${responsibility}</li>
                      `).join('')}
                    </ul>
                  </div>
                </div>
              `).join('')}
            </div>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Assumptions & Constraints</h3>
            <div class="grid grid-cols-2 gap-8">
              <div>
                <h4 class="font-medium text-slate-800 mb-3">Assumptions</h4>
                <ul class="list-disc pl-6 space-y-2 text-slate-600">
                  ${sowData.assumptions.map(assumption => `
                    <li>${assumption}</li>
                  `).join('')}
                </ul>
              </div>
              <div>
                <h4 class="font-medium text-slate-800 mb-3">Constraints</h4>
                <ul class="list-disc pl-6 space-y-2 text-slate-600">
                  ${sowData.constraints.map(constraint => `
                    <li>${constraint}</li>
                  `).join('')}
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Pricing</h3>
            <div class="border border-slate-200 rounded-lg p-6">
              <div class="grid grid-cols-2 gap-6">
                <div>
                  <p class="text-sm text-slate-500">Pricing Type</p>
                  <p class="text-slate-800 mt-1">${sowData.pricing.type}</p>
                </div>
                <div>
                  <p class="text-sm text-slate-500">Total Amount</p>
                  <p class="text-slate-800 mt-1">${sowData.pricing.currency} ${sowData.pricing.amount.toLocaleString()}</p>
                </div>
              </div>
              <div class="mt-4">
                <p class="text-sm text-slate-500">Payment Schedule</p>
                <p class="text-slate-600 mt-1">${sowData.pricing.paymentSchedule}</p>
              </div>
            </div>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Acceptance</h3>
            <div class="space-y-6">
              <div>
                <h4 class="font-medium text-slate-800 mb-3">Acceptance Criteria</h4>
                <ul class="list-disc pl-6 space-y-2 text-slate-600">
                  ${sowData.acceptance.criteria.map(criterion => `
                    <li>${criterion}</li>
                  `).join('')}
                </ul>
              </div>
              <div>
                <h4 class="font-medium text-slate-800 mb-3">Acceptance Process</h4>
                <p class="text-slate-600">${sowData.acceptance.process}</p>
              </div>
              <div>
                <h4 class="font-medium text-slate-800 mb-3">Acceptance Timeline</h4>
                <p class="text-slate-600">${sowData.acceptance.timeline}</p>
              </div>
            </div>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Project Governance</h3>
            <div class="space-y-6">
              <div>
                <h4 class="font-medium text-slate-800 mb-3">Reporting Structure</h4>
                <p class="text-slate-600">${sowData.governance.reportingStructure}</p>
              </div>
              <div>
                <h4 class="font-medium text-slate-800 mb-3">Communication Plan</h4>
                <p class="text-slate-600">${sowData.governance.communicationPlan}</p>
              </div>
              <div>
                <h4 class="font-medium text-slate-800 mb-3">Meeting Schedule</h4>
                <p class="text-slate-600">${sowData.governance.meetingSchedule}</p>
              </div>
            </div>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Client Responsibilities</h3>
            <ul class="list-disc pl-6 space-y-2 text-slate-600">
              ${sowData.clientResponsibilities.map(responsibility => `
                <li>${responsibility}</li>
              `).join('')}
            </ul>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Terms & Conditions</h3>
            <p class="text-slate-600 whitespace-pre-line">${sowData.termsAndConditions}</p>
          </section>
        </div>

        <div class="mt-16 pt-8 border-t">
          <p class="text-slate-600 mb-8">IN WITNESS WHEREOF, the parties hereto have executed this Statement of Work as of the date first above written.</p>
          
          <div class="grid grid-cols-2 gap-8">
            <div>
              <p class="text-sm text-slate-500 mb-8">Client Signature</p>
              <div class="border-b border-slate-800 w-48"></div>
              <p class="text-sm text-slate-500 mt-2">Date: _________________</p>
            </div>
            <div>
              <p class="text-sm text-slate-500 mb-8">Service Provider Signature</p>
              <div class="border-b border-slate-800 w-48"></div>
              <p class="text-sm text-slate-500 mt-2">Date: ${new Date(sowData.dateCreated).toLocaleDateString()}</p>
            </div>
          </div>
        </div>

        <div class="mt-16 pt-8 border-t text-center">
          <div class="flex items-center justify-center gap-2 text-slate-400">
            <div class="flex h-4 w-4 items-end justify-center rounded-sm bg-slate-800 p-[4px]">
              <div class="relative flex items-end gap-[1px]">
                <div class="h-[6px] w-[2px] rounded-full bg-white"></div>
                <div class="h-[8px] w-[2px] -translate-y-[1px] rounded-full bg-white"></div>
              </div>
            </div>
            <span class="text-sm">Generated with Lipana</span>
          </div>
        </div>
      </div>
    `;
  };

  const handleDownloadPDF = async () => {
    const html2pdf = (await import('html2pdf.js')).default;
    
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = generateFullSow();
    document.body.appendChild(tempContainer);
    
    const opt = {
      margin: 1,
      filename: `sow-${sowData.sowNumber}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    
    await html2pdf().set(opt).from(tempContainer).save();
    document.body.removeChild(tempContainer);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8"
    >
      <div className="flex justify-end mb-6 gap-3">
        <button
          onClick={() => setShowFullPreview(true)}
          className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
        >
          <RemoveRedEye className="w-4 h-4" />
          Preview Full SOW
        </button>
        <button
          onClick={handleDownloadPDF}
          className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
        >
          <FileDownload className="w-4 h-4" />
          Download PDF
        </button>
      </div>

      <div 
        id="sow-preview"
        className="space-y-8"
        dangerouslySetInnerHTML={{ __html: generateFullSow() }}
      />

      <AnimatePresence>
        {showFullPreview && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black"
              onClick={() => setShowFullPreview(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="fixed inset-4 bg-white rounded-xl overflow-auto p-8 max-w-4xl mx-auto"
              style={{ top: '2%', height: '96%' }}
            >
              <div className="flex justify-end mb-4">
                <button
                  onClick={() => setShowFullPreview(false)}
                  className="text-gray-500 hover:text-gray-700 rounded-full p-2 hover:bg-gray-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div 
                className="sow-full space-y-8"
                dangerouslySetInnerHTML={{ __html: generateFullSow() }}
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default SowPreview; 