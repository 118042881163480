import React from 'react';
import { motion } from 'framer-motion';
import { Description, CheckCircle, ArrowForward, Receipt, Bolt, CloudDone } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function ReceiptLanding() {
  const benefits = [
    {
      title: "Professional Format",
      description: "Create polished, professional receipts that build trust",
      icon: Receipt,
      color: "bg-purple-100 text-purple-600"
    },
    {
      title: "Instant Generation",
      description: "Generate detailed receipts in under 30 seconds",
      icon: Bolt,
      color: "bg-amber-100 text-amber-600"
    },
    {
      title: "Cloud Storage",
      description: "Safely store and access your receipts anytime",
      icon: CloudDone,
      color: "bg-blue-100 text-blue-600"
    }
  ];

  const howToSteps = [
    {
      title: "Enter Transaction Details",
      description: "Input payment information and transaction details",
      image: "/images/receipt-step1.svg"
    },
    {
      title: "Add Items",
      description: "List purchased items with quantities and prices",
      image: "/images/receipt-step2.svg"
    },
    {
      title: "Download & Share",
      description: "Get your receipt as a professional PDF ready to share",
      image: "/images/receipt-step3.svg"
    }
  ];

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Lipana Receipt Generator",
    "applicationCategory": "BusinessApplication",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "description": "Create professional receipts instantly with our easy-to-use receipt generator. Perfect for businesses and freelancers.",
  };

  return (
    <>
      <Helmet>
        <title>Free Receipt Generator | Create Professional Receipts Online</title>
        <meta name="description" content="Generate professional receipts instantly. Free online receipt generator with automatic calculations, custom branding, and professional templates." />
        <meta name="keywords" content="receipt generator, create receipt online, free receipt maker, professional receipt template, digital receipt" />
        <meta property="og:title" content="Free Receipt Generator | Create Professional Receipts Online" />
        <meta property="og:description" content="Generate professional receipts instantly. Free online receipt generator with automatic calculations." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://yourdomain.com/receipt" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div className="min-h-screen">
        <Header />

        {/* Hero Section */}
        <section className="relative overflow-hidden bg-gradient-to-br from-purple-900 to-purple-800 pt-32 pb-20 sm:pt-40 sm:pb-32">
          <div className="absolute inset-0">
            <div 
              className="absolute inset-0 bg-[url(/grid.svg)] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]" 
              style={{ 
                backgroundSize: '30px 30px',
                opacity: 0.2,
                maskImage: 'linear-gradient(to bottom, white, transparent)'
              }} 
            />
            <div className="absolute inset-0 bg-gradient-to-t from-purple-900" />
          </div>
          <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <motion.h1 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-4xl font-bold tracking-tight text-white sm:text-6xl"
              >
                Create Professional{' '}
                <span className="text-purple-400">Receipts</span>
                <br />in Seconds
              </motion.h1>
              <motion.p 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className="mx-auto mt-6 max-w-2xl text-lg text-purple-100"
              >
                Generate polished, professional receipts instantly.
                No accounting expertise required.
              </motion.p>
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="mt-10 flex justify-center gap-4"
              >
                <Link
                  to="/receipt/generate"
                  className="inline-flex items-center px-6 py-3 bg-white text-purple-900 rounded-lg font-semibold hover:bg-purple-50 transition-colors"
                >
                  Create Your Receipt
                  <ArrowForward className="ml-2 w-5 h-5" />
                </Link>
                <a
                  href="#how-it-works"
                  className="inline-flex items-center px-6 py-3 bg-purple-800 text-white rounded-lg font-semibold hover:bg-purple-700 transition-colors"
                >
                  Learn More
                </a>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="py-20 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-slate-900">
                Why Use Our Receipt Generator?
              </h2>
              <p className="mt-4 text-lg text-slate-600">
                Everything you need to create professional receipts, without the complexity
              </p>
            </div>
            <div className="grid md:grid-cols-3 gap-8">
              {benefits.map((benefit, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="relative p-8 bg-white rounded-2xl shadow-sm hover:shadow-md transition-shadow"
                >
                  <div className={`${benefit.color} w-12 h-12 rounded-xl flex items-center justify-center mb-6`}>
                    <benefit.icon className="w-6 h-6" />
                  </div>
                  <h3 className="text-xl font-semibold text-slate-900 mb-2">{benefit.title}</h3>
                  <p className="text-slate-600">{benefit.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        <section id="how-it-works" className="py-20 bg-slate-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-slate-900">
                How to Create Your Receipt
              </h2>
              <p className="mt-4 text-lg text-slate-600">
                Three simple steps to get your professional receipt
              </p>
            </div>
            <div className="space-y-20">
              {howToSteps.map((step, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  className={`flex flex-col ${
                    index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
                  } items-center gap-12`}
                >
                  <div className="flex-1">
                    <div className="inline-block px-4 py-2 bg-purple-100 text-purple-700 rounded-full text-sm font-semibold mb-4">
                      Step {index + 1}
                    </div>
                    <h3 className="text-2xl font-bold text-slate-900 mb-4">
                      {step.title}
                    </h3>
                    <p className="text-lg text-slate-600">{step.description}</p>
                  </div>
                  <div className="flex-1">
                    <img
                      src={step.image}
                      alt={step.title}
                      className="rounded-2xl shadow-lg"
                    />
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20 bg-purple-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-bold text-white mb-8">
              Ready to Create Your Professional Receipt?
            </h2>
            <p className="text-lg text-purple-100 mb-10 max-w-2xl mx-auto">
              Join thousands of businesses who trust our receipt generator
            </p>
            <Link
              to="/receipt/generate"
              className="inline-flex items-center px-8 py-4 bg-white text-purple-900 rounded-lg font-semibold hover:bg-purple-50 transition-colors"
            >
              Get Started Now
              <ArrowForward className="ml-2" />
            </Link>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
} 