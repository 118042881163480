import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { KeyboardArrowDown, Twitter } from '@mui/icons-material';
import Logo from './Logo';

const tools = [
  { name: 'Contract Generator', path: '/contract' },
  { name: 'Invoice Generator', path: '/invoice' },
  { name: 'Proposal Generator', path: '/proposal' },
  { name: 'Receipt Generator', path: '/receipt' },
  { name: 'Quote Generator', path: '/quote' },
  { name: 'Statement of Work', path: '/sow' },
  { name: 'Project Brief', path: '/brief' },
  { name: 'NDA Generator', path: '/nda' },
  { name: 'Time Sheet', path: '/timesheet' },
  { name: 'Expense Report', path: '/expense' },
  { name: 'Status Report', path: '/status' }
];

export default function Header() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-md border-b border-slate-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/" className="flex items-center gap-2">
            <Logo />
            <span className="font-semibold text-slate-900">Lipana</span>
          </Link>

          {/* Center Navigation */}
          <div className="flex items-center gap-6">
            {/* Tools Dropdown */}
            <div className="relative">
              <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="flex items-center gap-1 text-sm text-slate-600 hover:text-slate-900 px-4 py-2 rounded-lg hover:bg-slate-50"
              >
                Tools
                <KeyboardArrowDown className={`w-5 h-5 transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`} />
              </button>

              {isDropdownOpen && (
                <div 
                  className="absolute right-0 mt-2 w-64 bg-white rounded-xl shadow-lg border border-slate-200 py-2"
                  onMouseLeave={() => setIsDropdownOpen(false)}
                >
                  {tools.map((tool) => (
                    <Link
                      key={tool.path}
                      to={tool.path}
                      className="block px-4 py-2 text-sm text-slate-600 hover:bg-slate-50 hover:text-slate-900"
                    >
                      {tool.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            {/* AI Link with Enhanced Tag */}
            <div className="relative">
              <Link
                to="/ai"
                className="text-sm text-slate-600 hover:text-slate-900 px-4 py-2 rounded-lg hover:bg-slate-50"
              >
                AI
              </Link>
              
            </div>

            {/* About Link */}
            <Link
              to="/about"
              className="text-sm text-slate-600 hover:text-slate-900 px-4 py-2 rounded-lg hover:bg-slate-50"
            >
              About
            </Link>
          </div>

          {/* Right Side - Twitter */}
          <div>
            <a
              href="https://twitter.com/lipanaApp"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 px-4 py-2 text-sm bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              <Twitter className="w-4 h-4" />
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </header>
  );
} 
