import React, { useState } from 'react';
import { ExpenseReportData } from '../types/expense';
import { motion, AnimatePresence } from 'framer-motion';
import { FileDownload, RemoveRedEye } from '@mui/icons-material';

interface ExpenseReportPreviewProps {
  expenseData: ExpenseReportData;
}

const ExpenseReportPreview: React.FC<ExpenseReportPreviewProps> = ({ expenseData }) => {
  const [showFullPreview, setShowFullPreview] = useState(false);

  const getStatusColor = (status: string) => {
    const colors = {
      'Draft': 'text-slate-600',
      'Submitted': 'text-blue-600',
      'Approved': 'text-emerald-600',
      'Rejected': 'text-red-600'
    };
    return colors[status as keyof typeof colors] || 'text-slate-600';
  };

  const generateFullReport = () => {
    return `
      <div class="expense-report-full space-y-8 px-8 py-6">
        <div class="flex justify-between items-start">
          <div>
            <h2 class="text-3xl font-bold text-slate-800">EXPENSE REPORT</h2>
            <p class="text-slate-500 mt-1">#${expenseData.reportNumber}</p>
          </div>
          ${expenseData.logo ? `
            <div class="w-40">
              <img src="${expenseData.logo}" alt="Company logo" style="max-height: 80px; width: auto; object-fit: contain;" />
            </div>
          ` : ''}
        </div>

        <div class="grid grid-cols-2 gap-8 mt-8">
          <div>
            <p class="text-sm font-medium text-slate-500">Employee Details</p>
            <h3 class="font-medium text-slate-800 mt-2">${expenseData.employeeName}</h3>
            <p class="text-slate-600 mt-1">ID: ${expenseData.employeeId}</p>
            <p class="text-slate-600 mt-1">${expenseData.employeeEmail}</p>
            <p class="text-slate-600 mt-1">${expenseData.department}</p>
          </div>
          <div>
            <p class="text-sm font-medium text-slate-500">Report Details</p>
            <p class="text-slate-600 mt-2">Created: ${new Date(expenseData.dateCreated).toLocaleDateString()}</p>
            <p class="text-slate-600 mt-1">Period: ${new Date(expenseData.dateFrom).toLocaleDateString()} - ${new Date(expenseData.dateTo).toLocaleDateString()}</p>
            <p class="text-slate-600 mt-1">Status: <span class="${getStatusColor(expenseData.status)}">${expenseData.status}</span></p>
          </div>
        </div>

        ${expenseData.projectName || expenseData.clientName ? `
          <div class="mt-8">
            <p class="text-sm font-medium text-slate-500">Project Information</p>
            ${expenseData.projectName ? `<p class="text-slate-600 mt-2">Project: ${expenseData.projectName}</p>` : ''}
            ${expenseData.clientName ? `<p class="text-slate-600 mt-1">Client: ${expenseData.clientName}</p>` : ''}
          </div>
        ` : ''}

        <div class="mt-12">
          <h3 class="text-lg font-semibold text-slate-800 mb-6">Expenses</h3>
          <table class="w-full">
            <thead>
              <tr class="border-b border-slate-200">
                <th class="py-3 text-left text-sm font-medium text-slate-500">Date</th>
                <th class="py-3 text-left text-sm font-medium text-slate-500">Category</th>
                <th class="py-3 text-left text-sm font-medium text-slate-500">Description</th>
                <th class="py-3 text-right text-sm font-medium text-slate-500">Amount</th>
                <th class="py-3 text-center text-sm font-medium text-slate-500">Receipt</th>
              </tr>
            </thead>
            <tbody>
              ${expenseData.expenses.map(expense => `
                <tr class="border-b border-slate-100">
                  <td class="py-4 text-slate-600">${new Date(expense.date).toLocaleDateString()}</td>
                  <td class="py-4 text-slate-600">${expense.category}</td>
                  <td class="py-4 text-slate-600">${expense.description}</td>
                  <td class="py-4 text-right text-slate-600">$${expense.amount.toFixed(2)}</td>
                  <td class="py-4 text-center">
                    ${expense.receipt ? `
                      <img src="${expense.receipt}" alt="Receipt" style="max-height: 40px; margin: 0 auto;" />
                    ` : '-'}
                  </td>
                </tr>
              `).join('')}
            </tbody>
          </table>

          <div class="mt-6 flex justify-end">
            <div class="w-64 space-y-3">
              <div class="flex justify-between text-slate-600">
                <span>Subtotal</span>
                <span>$${expenseData.subtotal.toFixed(2)}</span>
              </div>
              <div class="flex justify-between text-slate-600">
                <span>Tax</span>
                <span>$${expenseData.tax.toFixed(2)}</span>
              </div>
              <div class="flex justify-between border-t border-slate-200 pt-3 font-medium text-slate-800">
                <span>Total</span>
                <span>$${expenseData.total.toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-12 space-y-6">
          <div>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Payment Details</h3>
            <div class="grid grid-cols-2 gap-8">
              <div>
                <p class="text-sm font-medium text-slate-500">Payment Method</p>
                <p class="text-slate-600 mt-2">${expenseData.paymentMethod}</p>
              </div>
              <div>
                <p class="text-sm font-medium text-slate-500">Reimbursement Method</p>
                <p class="text-slate-600 mt-2">${expenseData.reimbursementMethod}</p>
              </div>
            </div>
          </div>

          ${expenseData.reimbursementMethod === 'Direct Deposit' && expenseData.bankDetails ? `
            <div>
              <h4 class="font-medium text-slate-800 mb-4">Bank Details</h4>
              <div class="grid grid-cols-2 gap-8">
                <div>
                  <p class="text-sm font-medium text-slate-500">Account Name</p>
                  <p class="text-slate-600 mt-2">${expenseData.bankDetails.accountName}</p>
                </div>
                <div>
                  <p class="text-sm font-medium text-slate-500">Account Number</p>
                  <p class="text-slate-600 mt-2">${expenseData.bankDetails.accountNumber}</p>
                </div>
                <div>
                  <p class="text-sm font-medium text-slate-500">Bank Name</p>
                  <p class="text-slate-600 mt-2">${expenseData.bankDetails.bankName}</p>
                </div>
                ${expenseData.bankDetails.swiftCode ? `
                  <div>
                    <p class="text-sm font-medium text-slate-500">Swift Code</p>
                    <p class="text-slate-600 mt-2">${expenseData.bankDetails.swiftCode}</p>
                  </div>
                ` : ''}
              </div>
            </div>
          ` : ''}
        </div>

        ${expenseData.comments ? `
          <div class="mt-12">
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Comments</h3>
            <p class="text-slate-600 whitespace-pre-line">${expenseData.comments}</p>
          </div>
        ` : ''}

        <div class="mt-16 pt-8 border-t">
          <div class="grid grid-cols-2 gap-8">
            <div>
              <p class="text-sm text-slate-500 mb-8">Employee Signature</p>
              <div class="border-b border-slate-800 w-48"></div>
              <p class="text-sm text-slate-600 mt-2">${expenseData.employeeName}</p>
              <p class="text-sm text-slate-500 mt-1">Date: ${new Date(expenseData.dateCreated).toLocaleDateString()}</p>
            </div>
            ${expenseData.approverName ? `
              <div>
                <p class="text-sm text-slate-500 mb-8">Approver Signature</p>
                <div class="border-b border-slate-800 w-48"></div>
                <p class="text-sm text-slate-600 mt-2">${expenseData.approverName}</p>
                <p class="text-sm text-slate-500 mt-1">Date: _________________</p>
              </div>
            ` : ''}
          </div>
        </div>

        <div class="mt-16 pt-8 border-t text-center">
          <div class="flex items-center justify-center gap-2 text-slate-400">
            <div class="flex h-4 w-4 items-end justify-center rounded-sm bg-slate-800 p-[4px]">
              <div class="relative flex items-end gap-[1px]">
                <div class="h-[6px] w-[2px] rounded-full bg-white"></div>
                <div class="h-[8px] w-[2px] -translate-y-[1px] rounded-full bg-white"></div>
              </div>
            </div>
            <span class="text-sm">Generated with Lipana</span>
          </div>
        </div>
      </div>
    `;
  };

  const handleDownloadPDF = async () => {
    const html2pdf = (await import('html2pdf.js')).default;
    
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = generateFullReport();
    document.body.appendChild(tempContainer);
    
    const opt = {
      margin: 1,
      filename: `expense-report-${expenseData.reportNumber}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    
    await html2pdf().set(opt).from(tempContainer).save();
    document.body.removeChild(tempContainer);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8"
    >
      <div className="flex justify-end mb-6 gap-3">
        <button
          onClick={() => setShowFullPreview(true)}
          className="flex items-center gap-2 px-4 py-2 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 transition-colors"
        >
          <RemoveRedEye className="w-4 h-4" />
          Preview Full Report
        </button>
        <button
          onClick={handleDownloadPDF}
          className="flex items-center gap-2 px-4 py-2 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 transition-colors"
        >
          <FileDownload className="w-4 h-4" />
          Download PDF
        </button>
      </div>

      <div 
        id="expense-report-preview"
        className="space-y-8"
        dangerouslySetInnerHTML={{ __html: generateFullReport() }}
      />

      <AnimatePresence>
        {showFullPreview && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black"
              onClick={() => setShowFullPreview(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="fixed inset-4 bg-white rounded-xl overflow-auto p-8 max-w-4xl mx-auto"
              style={{ top: '2%', height: '96%' }}
            >
              <div className="flex justify-end mb-4">
                <button
                  onClick={() => setShowFullPreview(false)}
                  className="text-gray-500 hover:text-gray-700 rounded-full p-2 hover:bg-gray-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div 
                className="expense-report-full space-y-8"
                dangerouslySetInnerHTML={{ __html: generateFullReport() }}
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ExpenseReportPreview; 