import React from 'react';
import { motion } from 'framer-motion';
import { 
  Description, 
  ReceiptLong, 
  PieChart,
  CheckCircle,
  Inventory,
  Warning,
  Search,
  Key,
  AccessTime,
  Receipt,
  Assessment
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const apps = [
  {
    title: 'Contract Generator',
    description: 'Create professional freelance contracts powered by AI',
    icon: Description,
    path: '/contract',
    color: 'bg-slate-800'
  },
  {
    title: 'Invoice Generator',
    description: 'Generate professional invoices in seconds',
    icon: ReceiptLong,
    path: '/invoice',
    color: 'bg-emerald-600'
  },
  {
    title: 'Proposal Generator',
    description: 'Craft winning business proposals with ease',
    icon: PieChart,
    path: '/proposal',
    color: 'bg-blue-600'
  },
  {
    title: 'Receipt Generator',
    description: 'Generate professional receipts instantly',
    icon: CheckCircle,
    path: '/receipt',
    color: 'bg-purple-600'
  },
  {
    title: 'Quote Generator',
    description: 'Create quick and professional price quotes',
    icon: Inventory,
    path: '/quote',
    color: 'bg-orange-600'
  },
  {
    title: 'Statement of Work',
    description: 'Generate detailed project specifications',
    icon: Warning,
    path: '/sow',
    color: 'bg-red-600'
  },
  {
    title: 'Project Brief',
    description: 'Create structured project briefs and requirements',
    icon: Search,
    path: '/brief',
    color: 'bg-teal-600'
  },
  {
    title: 'NDA Generator',
    description: 'Create secure non-disclosure agreements',
    icon: Key,
    path: '/nda',
    color: 'bg-indigo-600'
  },
  {
    title: 'Time Sheet',
    description: 'Track and report billable hours',
    icon: AccessTime,
    path: '/timesheet',
    color: 'bg-pink-600'
  },
  {
    title: 'Expense Report',
    description: 'Track and manage project expenses',
    icon: Receipt,
    path: '/expense',
    color: 'bg-yellow-600'
  },
  {
    title: 'Status Report',
    description: 'Generate professional project status reports',
    icon: Assessment,
    path: '/status',
    color: 'bg-cyan-600'
  }
];

export default function Home() {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      
      <main className="flex-grow bg-gradient-to-br from-slate-50 to-white px-8 pt-24 pb-12">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-16"
          >
            
              
            <h1 className="text-2xl font-bold text-slate-800 mb-4">
              Professional document generation suite for freelancers
            </h1>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {apps.map((app, index) => (
              <motion.div
                key={app.path}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <Link
                  to={app.path}
                  className="block group"
                >
                  <div className="bg-white rounded-xl shadow-xl p-8 transition-transform duration-200 group-hover:-translate-y-1">
                    <div className={`${app.color} w-12 h-12 rounded-lg flex items-center justify-center mb-6`}>
                      <app.icon className="w-6 h-6 text-white" />
                    </div>
                    <h2 className="text-xl font-semibold text-slate-800 mb-2">
                      {app.title}
                    </h2>
                    <p className="text-slate-600">
                      {app.description}
                    </p>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
} 