import React, { useState } from 'react';
import { TimesheetData } from '../types/timesheet';
import { motion, AnimatePresence } from 'framer-motion';
import { FileDownload, RemoveRedEye } from '@mui/icons-material';

interface TimeSheetPreviewProps {
  timesheetData: TimesheetData;
}

const TimeSheetPreview: React.FC<TimeSheetPreviewProps> = ({ timesheetData }) => {
  const [showFullPreview, setShowFullPreview] = useState(false);

  const getStatusColor = (status: string) => {
    const colors = {
      'Draft': 'text-slate-600',
      'Submitted': 'text-blue-600',
      'Approved': 'text-emerald-600',
      'Rejected': 'text-red-600'
    };
    return colors[status as keyof typeof colors] || 'text-slate-600';
  };

  const generateFullTimesheet = () => {
    return `
      <div class="timesheet-full space-y-8 px-8 py-6">
        <div class="flex justify-between items-start">
          <div>
            <h2 class="text-3xl font-bold text-slate-800">TIME SHEET</h2>
            <p class="text-slate-500 mt-1">#${timesheetData.timesheetNumber}</p>
          </div>
          ${timesheetData.logo ? `
            <div class="w-40">
              <img src="${timesheetData.logo}" alt="Company logo" style="max-height: 80px; width: auto; object-fit: contain;" />
            </div>
          ` : ''}
        </div>

        <div class="grid grid-cols-2 gap-8 mt-8">
          <div>
            <p class="text-sm font-medium text-slate-500">Employee Details</p>
            <h3 class="font-medium text-slate-800 mt-2">${timesheetData.employeeName}</h3>
            <p class="text-slate-600 mt-1">ID: ${timesheetData.employeeId}</p>
            <p class="text-slate-600 mt-1">${timesheetData.employeeEmail}</p>
            <p class="text-slate-600 mt-1">${timesheetData.department}</p>
          </div>
          <div>
            <p class="text-sm font-medium text-slate-500">Report Details</p>
            <p class="text-slate-600 mt-2">Created: ${new Date(timesheetData.dateCreated).toLocaleDateString()}</p>
            <p class="text-slate-600 mt-1">Period: ${new Date(timesheetData.periodStart).toLocaleDateString()} - ${new Date(timesheetData.periodEnd).toLocaleDateString()}</p>
            <p class="text-slate-600 mt-1">Status: <span class="${getStatusColor(timesheetData.status)}">${timesheetData.status}</span></p>
          </div>
        </div>

        ${timesheetData.project || timesheetData.client ? `
          <div class="mt-8">
            <p class="text-sm font-medium text-slate-500">Project Information</p>
            ${timesheetData.project ? `<p class="text-slate-600 mt-2">Project: ${timesheetData.project}</p>` : ''}
            ${timesheetData.client ? `<p class="text-slate-600 mt-1">Client: ${timesheetData.client}</p>` : ''}
          </div>
        ` : ''}

        <div class="mt-12">
          <h3 class="text-lg font-semibold text-slate-800 mb-6">Time Entries</h3>
          <table class="w-full">
            <thead>
              <tr class="border-b border-slate-200">
                <th class="py-3 text-left text-sm font-medium text-slate-500">Date</th>
                <th class="py-3 text-left text-sm font-medium text-slate-500">Project</th>
                <th class="py-3 text-left text-sm font-medium text-slate-500">Task</th>
                <th class="py-3 text-center text-sm font-medium text-slate-500">Start</th>
                <th class="py-3 text-center text-sm font-medium text-slate-500">End</th>
                <th class="py-3 text-right text-sm font-medium text-slate-500">Hours</th>
                <th class="py-3 text-center text-sm font-medium text-slate-500">Billable</th>
              </tr>
            </thead>
            <tbody>
              ${timesheetData.entries.map(entry => `
                <tr class="border-b border-slate-100">
                  <td class="py-4 text-slate-600">${new Date(entry.date).toLocaleDateString()}</td>
                  <td class="py-4 text-slate-600">${entry.project}</td>
                  <td class="py-4 text-slate-600">${entry.task}</td>
                  <td class="py-4 text-center text-slate-600">${entry.startTime}</td>
                  <td class="py-4 text-center text-slate-600">${entry.endTime}</td>
                  <td class="py-4 text-right text-slate-600">${entry.duration.toFixed(2)}</td>
                  <td class="py-4 text-center">
                    <span class="${entry.billable ? 'text-emerald-600' : 'text-slate-600'}">
                      ${entry.billable ? 'Yes' : 'No'}
                    </span>
                  </td>
                </tr>
              `).join('')}
            </tbody>
          </table>

          <div class="mt-6 flex justify-end">
            <div class="w-64 space-y-3">
              <div class="flex justify-between text-slate-600">
                <span>Total Hours</span>
                <span>${timesheetData.totalHours.toFixed(2)}</span>
              </div>
              <div class="flex justify-between text-slate-600">
                <span>Billable Hours</span>
                <span>${timesheetData.billableHours.toFixed(2)}</span>
              </div>
              <div class="flex justify-between text-slate-600">
                <span>Non-Billable Hours</span>
                <span>${timesheetData.nonBillableHours.toFixed(2)}</span>
              </div>
              <div class="flex justify-between border-t border-slate-200 pt-3 font-medium text-slate-800">
                <span>Total Amount</span>
                <span>$${timesheetData.totalAmount.toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>

        ${timesheetData.comments ? `
          <div class="mt-12">
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Comments</h3>
            <p class="text-slate-600 whitespace-pre-line">${timesheetData.comments}</p>
          </div>
        ` : ''}

        <div class="mt-16 pt-8 border-t">
          <div class="grid grid-cols-2 gap-8">
            <div>
              <p class="text-sm text-slate-500 mb-8">Employee Signature</p>
              <div class="border-b border-slate-800 w-48"></div>
              <p class="text-sm text-slate-600 mt-2">${timesheetData.employeeName}</p>
              <p class="text-sm text-slate-500 mt-1">Date: ${new Date(timesheetData.dateCreated).toLocaleDateString()}</p>
            </div>
            ${timesheetData.approverName ? `
              <div>
                <p class="text-sm text-slate-500 mb-8">Approver Signature</p>
                <div class="border-b border-slate-800 w-48"></div>
                <p class="text-sm text-slate-600 mt-2">${timesheetData.approverName}</p>
                <p class="text-sm text-slate-500 mt-1">Date: ${timesheetData.approvalDate ? new Date(timesheetData.approvalDate).toLocaleDateString() : '_________________'}</p>
              </div>
            ` : ''}
          </div>
        </div>

        <div class="mt-16 pt-8 border-t text-center">
          <div class="flex items-center justify-center gap-2 text-slate-400">
            <div class="flex h-4 w-4 items-end justify-center rounded-sm bg-slate-800 p-[4px]">
              <div class="relative flex items-end gap-[1px]">
                <div class="h-[6px] w-[2px] rounded-full bg-white"></div>
                <div class="h-[8px] w-[2px] -translate-y-[1px] rounded-full bg-white"></div>
              </div>
            </div>
            <span class="text-sm">Generated with Lipana</span>
          </div>
        </div>
      </div>
    `;
  };

  const handleDownloadPDF = async () => {
    const html2pdf = (await import('html2pdf.js')).default;
    
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = generateFullTimesheet();
    document.body.appendChild(tempContainer);
    
    const opt = {
      margin: 1,
      filename: `timesheet-${timesheetData.timesheetNumber}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    
    await html2pdf().set(opt).from(tempContainer).save();
    document.body.removeChild(tempContainer);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8"
    >
      <div className="flex justify-end mb-6 gap-3">
        <button
          onClick={() => setShowFullPreview(true)}
          className="flex items-center gap-2 px-4 py-2 bg-pink-600 text-white rounded-lg hover:bg-pink-700 transition-colors"
        >
          <RemoveRedEye className="w-4 h-4" />
          Preview Full Timesheet
        </button>
        <button
          onClick={handleDownloadPDF}
          className="flex items-center gap-2 px-4 py-2 bg-pink-600 text-white rounded-lg hover:bg-pink-700 transition-colors"
        >
          <FileDownload className="w-4 h-4" />
          Download PDF
        </button>
      </div>

      <div 
        id="timesheet-preview"
        className="space-y-8"
        dangerouslySetInnerHTML={{ __html: generateFullTimesheet() }}
      />

      <AnimatePresence>
        {showFullPreview && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black"
              onClick={() => setShowFullPreview(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="fixed inset-4 bg-white rounded-xl overflow-auto p-8 max-w-4xl mx-auto"
              style={{ top: '2%', height: '96%' }}
            >
              <div className="flex justify-end mb-4">
                <button
                  onClick={() => setShowFullPreview(false)}
                  className="text-gray-500 hover:text-gray-700 rounded-full p-2 hover:bg-gray-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div 
                className="timesheet-full space-y-8"
                dangerouslySetInnerHTML={{ __html: generateFullTimesheet() }}
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default TimeSheetPreview; 