import React, { useState } from 'react';
import { StatusReportData } from '../types/statusReport';
import { motion, AnimatePresence } from 'framer-motion';
import { FileDownload, RemoveRedEye } from '@mui/icons-material';

interface StatusReportPreviewProps {
  reportData: StatusReportData;
}

const StatusReportPreview: React.FC<StatusReportPreviewProps> = ({ reportData }) => {
  const [showFullPreview, setShowFullPreview] = useState(false);

  const getStatusColor = (status: string) => {
    const colors = {
      'Completed': 'text-emerald-600',
      'In Progress': 'text-blue-600',
      'Pending': 'text-orange-600',
      'Delayed': 'text-red-600'
    };
    return colors[status as keyof typeof colors] || 'text-slate-600';
  };

  const getRiskColor = (impact: string) => {
    const colors = {
      'High': 'text-red-600',
      'Medium': 'text-orange-600',
      'Low': 'text-emerald-600'
    };
    return colors[impact as keyof typeof colors] || 'text-slate-600';
  };

  const generateFullReport = () => {
    return `
      <div class="report-full space-y-8 px-8 py-6">
        <div class="flex justify-between items-start">
          <div>
            <h2 class="text-3xl font-bold text-slate-800">PROJECT STATUS REPORT</h2>
            <p class="text-slate-500 mt-1">#${reportData.reportNumber}</p>
          </div>
          ${reportData.logo ? `
            <div class="w-40">
              <img src="${reportData.logo}" alt="Project logo" style="max-height: 80px; width: auto; object-fit: contain;" />
            </div>
          ` : ''}
        </div>

        <div class="grid grid-cols-2 gap-8 mt-8">
          <div>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">${reportData.projectTitle}</h3>
            <div class="space-y-2">
              <p class="text-sm text-slate-500">Project Manager</p>
              <p class="text-slate-800">${reportData.projectManager}</p>
            </div>
          </div>
          <div class="space-y-4">
            <div>
              <p class="text-sm text-slate-500">Client</p>
              <p class="text-slate-800">${reportData.clientName}</p>
              <p class="text-slate-600">${reportData.clientEmail}</p>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-3 gap-8 mt-8">
          <div>
            <p class="text-sm text-slate-500">Report Date</p>
            <p class="text-slate-800 mt-1">${new Date(reportData.dateCreated).toLocaleDateString()}</p>
          </div>
          <div>
            <p class="text-sm text-slate-500">Reporting Period</p>
            <p class="text-slate-800 mt-1">${new Date(reportData.reportingPeriod.from).toLocaleDateString()} - ${new Date(reportData.reportingPeriod.to).toLocaleDateString()}</p>
          </div>
          <div>
            <p class="text-sm text-slate-500">Project Status</p>
            <p class="${reportData.scheduleStatus.onTrack ? 'text-emerald-600' : 'text-red-600'} mt-1 font-medium">
              ${reportData.scheduleStatus.onTrack ? 'On Track' : 'Delayed'}
            </p>
          </div>
        </div>

        <div class="space-y-8 mt-12">
          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Executive Summary</h3>
            <p class="text-slate-600 whitespace-pre-line">${reportData.executiveSummary}</p>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Key Accomplishments</h3>
            <p class="text-slate-600 whitespace-pre-line">${reportData.accomplishments}</p>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Milestones</h3>
            <div class="space-y-4">
              ${reportData.milestones.map(milestone => `
                <div class="border border-slate-200 rounded-lg p-4">
                  <div class="flex justify-between items-start">
                    <div>
                      <h4 class="font-medium text-slate-800">${milestone.title}</h4>
                      <p class="text-sm text-slate-600 mt-1">${milestone.notes}</p>
                    </div>
                    <div class="text-right">
                      <span class="${getStatusColor(milestone.status)} font-medium">${milestone.status}</span>
                      <div class="text-sm text-slate-500 mt-1">${milestone.progress}% Complete</div>
                    </div>
                  </div>
                  <div class="mt-3 w-full bg-slate-200 rounded-full h-2">
                    <div class="bg-blue-600 h-2 rounded-full" style="width: ${milestone.progress}%"></div>
                  </div>
                </div>
              `).join('')}
            </div>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Upcoming Tasks</h3>
            <p class="text-slate-600 whitespace-pre-line">${reportData.upcomingTasks}</p>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Risks & Issues</h3>
            <div class="space-y-4">
              ${reportData.risks.map(risk => `
                <div class="border border-slate-200 rounded-lg p-4">
                  <div class="flex justify-between items-start">
                    <div class="flex-1">
                      <p class="text-slate-800">${risk.description}</p>
                      <p class="text-sm text-slate-600 mt-1">Mitigation: ${risk.mitigation}</p>
                    </div>
                    <span class="ml-4 ${getRiskColor(risk.impact)} font-medium">${risk.impact} Impact</span>
                  </div>
                </div>
              `).join('')}
            </div>
          </section>

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Budget Status</h3>
            <div class="grid grid-cols-3 gap-8">
              <div class="border border-slate-200 rounded-lg p-4">
                <p class="text-sm text-slate-500">Budgeted</p>
                <p class="text-xl font-medium text-slate-800 mt-1">$${reportData.budgetStatus.budgeted.toLocaleString()}</p>
              </div>
              <div class="border border-slate-200 rounded-lg p-4">
                <p class="text-sm text-slate-500">Spent</p>
                <p class="text-xl font-medium text-slate-800 mt-1">$${reportData.budgetStatus.spent.toLocaleString()}</p>
              </div>
              <div class="border border-slate-200 rounded-lg p-4">
                <p class="text-sm text-slate-500">Remaining</p>
                <p class="text-xl font-medium ${reportData.budgetStatus.remaining >= 0 ? 'text-emerald-600' : 'text-red-600'} mt-1">
                  $${reportData.budgetStatus.remaining.toLocaleString()}
                </p>
              </div>
            </div>
          </section>

          ${!reportData.scheduleStatus.onTrack ? `
            <section>
              <h3 class="text-lg font-semibold text-slate-800 mb-4">Schedule Status</h3>
              <div class="border border-slate-200 rounded-lg p-4 space-y-4">
                <div>
                  <p class="text-sm text-slate-500">Delay Description</p>
                  <p class="text-slate-800 mt-1">${reportData.scheduleStatus.delay}</p>
                </div>
                <div>
                  <p class="text-sm text-slate-500">Recovery Plan</p>
                  <p class="text-slate-800 mt-1">${reportData.scheduleStatus.recoveryPlan}</p>
                </div>
              </div>
            </section>
          ` : ''}

          <section>
            <h3 class="text-lg font-semibold text-slate-800 mb-4">Next Steps</h3>
            <p class="text-slate-600 whitespace-pre-line">${reportData.nextSteps}</p>
          </section>

          ${reportData.additionalNotes ? `
            <section>
              <h3 class="text-lg font-semibold text-slate-800 mb-4">Additional Notes</h3>
              <p class="text-slate-600 whitespace-pre-line">${reportData.additionalNotes}</p>
            </section>
          ` : ''}
        </div>

        <div class="mt-16 pt-8 border-t text-center">
          <div class="flex items-center justify-center gap-2 text-slate-400">
            <div class="flex h-4 w-4 items-end justify-center rounded-sm bg-slate-800 p-[4px]">
              <div class="relative flex items-end gap-[1px]">
                <div class="h-[6px] w-[2px] rounded-full bg-white"></div>
                <div class="h-[8px] w-[2px] -translate-y-[1px] rounded-full bg-white"></div>
              </div>
            </div>
            <span class="text-sm">Generated with Lipana</span>
          </div>
        </div>
      </div>
    `;
  };

  const handleDownloadPDF = async () => {
    const html2pdf = (await import('html2pdf.js')).default;
    
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = generateFullReport();
    document.body.appendChild(tempContainer);
    
    const opt = {
      margin: 1,
      filename: `status-report-${reportData.reportNumber}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    
    await html2pdf().set(opt).from(tempContainer).save();
    document.body.removeChild(tempContainer);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8"
    >
      <div className="flex justify-end mb-6 gap-3">
        <button
          onClick={() => setShowFullPreview(true)}
          className="flex items-center gap-2 px-4 py-2 bg-cyan-600 text-white rounded-lg hover:bg-cyan-700 transition-colors"
        >
          <RemoveRedEye className="w-4 h-4" />
          Preview Full Report
        </button>
        <button
          onClick={handleDownloadPDF}
          className="flex items-center gap-2 px-4 py-2 bg-cyan-600 text-white rounded-lg hover:bg-cyan-700 transition-colors"
        >
          <FileDownload className="w-4 h-4" />
          Download PDF
        </button>
      </div>

      <div 
        id="report-preview"
        className="space-y-8"
        dangerouslySetInnerHTML={{ __html: generateFullReport() }}
      />

      <AnimatePresence>
        {showFullPreview && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black"
              onClick={() => setShowFullPreview(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="fixed inset-4 bg-white rounded-xl overflow-auto p-8 max-w-4xl mx-auto"
              style={{ top: '2%', height: '96%' }}
            >
              <div className="flex justify-end mb-4">
                <button
                  onClick={() => setShowFullPreview(false)}
                  className="text-gray-500 hover:text-gray-700 rounded-full p-2 hover:bg-gray-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div 
                className="report-full space-y-8"
                dangerouslySetInnerHTML={{ __html: generateFullReport() }}
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default StatusReportPreview; 