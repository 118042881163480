import React from 'react';
import { motion } from 'framer-motion';
import { Description, CheckCircle, ArrowForward, Assignment, Bolt, ManageSearch } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function BriefLanding() {
  const benefits = [
    {
      title: "Clear Communication",
      description: "Define project requirements with crystal clarity",
      icon: Assignment,
      color: "bg-teal-100 text-teal-600"
    },
    {
      title: "Quick Setup",
      description: "Create comprehensive briefs in minutes",
      icon: Bolt,
      color: "bg-amber-100 text-amber-600"
    },
    {
      title: "Better Alignment",
      description: "Ensure everyone is on the same page from the start",
      icon: ManageSearch,
      color: "bg-blue-100 text-blue-600"
    }
  ];

  const howToSteps = [
    {
      title: "Project Overview",
      description: "Define your project goals and objectives",
      image: "/images/brief-step1.svg"
    },
    {
      title: "Add Requirements",
      description: "Specify detailed project requirements and specifications",
      image: "/images/brief-step2.svg"
    },
    {
      title: "Share & Collaborate",
      description: "Share your brief with stakeholders and get feedback",
      image: "/images/brief-step3.svg"
    }
  ];

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Lipana Brief Generator",
    "applicationCategory": "BusinessApplication",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "description": "Create professional project briefs instantly with our easy-to-use brief generator. Perfect for project managers and teams.",
  };

  return (
    <>
      <Helmet>
        <title>Free Project Brief Generator | Create Professional Project Briefs Online</title>
        <meta name="description" content="Generate professional project briefs in minutes. Free online brief generator with customizable templates and collaboration features." />
        <meta name="keywords" content="project brief generator, brief template, project requirements, project scope, professional brief" />
        <meta property="og:title" content="Free Project Brief Generator | Create Professional Project Briefs Online" />
        <meta property="og:description" content="Generate professional project briefs in minutes. Free online brief generator with customizable templates." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://yourdomain.com/brief" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div className="min-h-screen">
        <Header />

        {/* Hero Section */}
        <section className="relative overflow-hidden bg-gradient-to-br from-teal-900 to-teal-800 pt-32 pb-20 sm:pt-40 sm:pb-32">
          <div className="absolute inset-0">
            <div 
              className="absolute inset-0 bg-[url(/grid.svg)] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]" 
              style={{ 
                backgroundSize: '30px 30px',
                opacity: 0.2,
                maskImage: 'linear-gradient(to bottom, white, transparent)'
              }} 
            />
            <div className="absolute inset-0 bg-gradient-to-t from-teal-900" />
          </div>
          <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <motion.h1 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-4xl font-bold tracking-tight text-white sm:text-6xl"
              >
                Create Professional{' '}
                <span className="text-teal-400">Project Briefs</span>
                <br />in Minutes
              </motion.h1>
              <motion.p 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className="mx-auto mt-6 max-w-2xl text-lg text-teal-100"
              >
                Define project requirements with clarity and precision.
                No project management expertise required.
              </motion.p>
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="mt-10 flex justify-center gap-4"
              >
                <Link
                  to="/brief/generate"
                  className="inline-flex items-center px-6 py-3 bg-white text-teal-900 rounded-lg font-semibold hover:bg-teal-50 transition-colors"
                >
                  Create Your Brief
                  <ArrowForward className="ml-2 w-5 h-5" />
                </Link>
                <a
                  href="#how-it-works"
                  className="inline-flex items-center px-6 py-3 bg-teal-800 text-white rounded-lg font-semibold hover:bg-teal-700 transition-colors"
                >
                  Learn More
                </a>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="py-20 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-slate-900">
                Why Use Our Brief Generator?
              </h2>
              <p className="mt-4 text-lg text-slate-600">
                Everything you need to create professional project briefs, without the complexity
              </p>
            </div>
            <div className="grid md:grid-cols-3 gap-8">
              {benefits.map((benefit, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="relative p-8 bg-white rounded-2xl shadow-sm hover:shadow-md transition-shadow"
                >
                  <div className={`${benefit.color} w-12 h-12 rounded-xl flex items-center justify-center mb-6`}>
                    <benefit.icon className="w-6 h-6" />
                  </div>
                  <h3 className="text-xl font-semibold text-slate-900 mb-2">{benefit.title}</h3>
                  <p className="text-slate-600">{benefit.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        <section id="how-it-works" className="py-20 bg-slate-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-slate-900">
                How to Create Your Brief
              </h2>
              <p className="mt-4 text-lg text-slate-600">
                Three simple steps to get your professional project brief
              </p>
            </div>
            <div className="space-y-20">
              {howToSteps.map((step, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  className={`flex flex-col ${
                    index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
                  } items-center gap-12`}
                >
                  <div className="flex-1">
                    <div className="inline-block px-4 py-2 bg-teal-100 text-teal-700 rounded-full text-sm font-semibold mb-4">
                      Step {index + 1}
                    </div>
                    <h3 className="text-2xl font-bold text-slate-900 mb-4">
                      {step.title}
                    </h3>
                    <p className="text-lg text-slate-600">{step.description}</p>
                  </div>
                  <div className="flex-1">
                    <img
                      src={step.image}
                      alt={step.title}
                      className="rounded-2xl shadow-lg"
                    />
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20 bg-teal-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-bold text-white mb-8">
              Ready to Create Your Professional Brief?
            </h2>
            <p className="text-lg text-teal-100 mb-10 max-w-2xl mx-auto">
              Join thousands of project managers who trust our brief generator
            </p>
            <Link
              to="/brief/generate"
              className="inline-flex items-center px-8 py-4 bg-white text-teal-900 rounded-lg font-semibold hover:bg-teal-50 transition-colors"
            >
              Get Started Now
              <ArrowForward className="ml-2" />
            </Link>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
} 