import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Description, AutoGraph, Security, Support, Speed, Code } from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function About() {
  const features = [
    {
      icon: Description,
      title: "Professional Templates",
      description: "Access a wide range of professionally designed templates for all your business documents.",
      color: "bg-blue-500"
    },
    {
      icon: AutoGraph,
      title: "Smart Automation",
      description: "Save time with automatic calculations and smart document generation features.",
      color: "bg-green-500"
    },
    {
      icon: Security,
      title: "Secure & Private",
      description: "Your data stays on your device. We don't store any of your information.",
      color: "bg-purple-500"
    },
    {
      icon: Support,
      title: "Free to Use",
      description: "All our tools are completely free to use, with no hidden costs or subscriptions.",
      color: "bg-red-500"
    },
    {
      icon: Speed,
      title: "Lightning Fast",
      description: "Generate professional documents in seconds with our optimized platform.",
      color: "bg-yellow-500"
    },
    {
      icon: Code,
      title: "Regular Updates",
      description: "We continuously improve our tools and add new features based on user feedback.",
      color: "bg-indigo-500"
    }
  ];

  return (
    <>
      <Helmet>
        <title>About Lipana | Free Business Document Generator</title>
        <meta name="description" content="Learn more about Lipana - your all-in-one solution for creating professional business documents. Free tools for freelancers and small businesses." />
      </Helmet>

      <div className="min-h-screen">
        <Header />
        
        <main className="pt-32 pb-20">
          {/* Hero Section */}
          <div className="relative overflow-hidden bg-gradient-to-br from-slate-900 to-slate-800 py-20 mb-20">
            <div 
              className="absolute inset-0 bg-[url(/grid.svg)] bg-center"
              style={{ 
                backgroundSize: '30px 30px',
                opacity: 0.2,
                maskImage: 'linear-gradient(to bottom, white, transparent)'
              }} 
            />
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-4xl font-bold text-white mb-6"
              >
                About Lipana
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className="text-xl text-slate-300 max-w-3xl mx-auto"
              >
                Empowering freelancers and small businesses with professional document generation tools
              </motion.p>
            </div>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {/* Mission Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-center mb-20"
            >
              <h2 className="text-3xl font-bold text-slate-900 mb-6">Our Mission</h2>
              <p className="text-lg text-slate-600 max-w-3xl mx-auto">
                At Lipana, we believe that every freelancer and small business deserves access to professional-grade business documents. 
                Our mission is to simplify the document creation process, making it accessible, efficient, and free for everyone.
              </p>
            </motion.div>

            {/* Features Grid */}
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-20">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow p-6"
                >
                  <div className={`${feature.color} w-12 h-12 rounded-lg flex items-center justify-center mb-4`}>
                    <feature.icon className="w-6 h-6 text-white" />
                  </div>
                  <h3 className="text-xl font-semibold text-slate-900 mb-2">{feature.title}</h3>
                  <p className="text-slate-600">{feature.description}</p>
                </motion.div>
              ))}
            </div>

            {/* CTA Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="text-center bg-gradient-to-r from-slate-800 to-slate-900 rounded-2xl p-12"
            >
              <h2 className="text-3xl font-bold text-white mb-6">Ready to Get Started?</h2>
              <p className="text-lg text-slate-300 mb-8 max-w-2xl mx-auto">
                Join thousands of freelancers and small businesses who trust Lipana for their document generation needs.
              </p>
              <Link
                to="/"
                className="inline-flex items-center px-6 py-3 bg-white text-slate-900 rounded-lg font-semibold hover:bg-slate-50 transition-colors"
              >
                Explore Our Tools
              </Link>
            </motion.div>
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
} 