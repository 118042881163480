import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// Home, About, and AI
import Home from './pages/Home';
import About from './pages/About';
import AiLanding from './pages/AiLanding';

// Landing Pages
import ContractLanding from './pages/ContractLanding';
import InvoiceLanding from './pages/InvoiceLanding';
import ProposalLanding from './pages/ProposalLanding';
import ReceiptLanding from './pages/ReceiptLanding';
import QuoteLanding from './pages/QuoteLanding';
import SowLanding from './pages/SowLanding';
import BriefLanding from './pages/BriefLanding';
import NdaLanding from './pages/NdaLanding';
import TimeSheetLanding from './pages/TimeSheetLanding';
import ExpenseReportLanding from './pages/ExpenseReportLanding';
import StatusReportLanding from './pages/StatusReportLanding';

// Generator Pages
import ContractGenerator from './pages/ContractGenerator';
import InvoiceGenerator from './pages/InvoiceGenerator';
import ProposalGenerator from './pages/ProposalGenerator';
import ReceiptGenerator from './pages/ReceiptGenerator';
import QuoteGenerator from './pages/QuoteGenerator';
import SowGenerator from './pages/SowGenerator';
import BriefGenerator from './pages/BriefGenerator';
import NdaGenerator from './pages/NdaGenerator';
import TimeSheetGenerator from './pages/TimeSheetGenerator';
import ExpenseReportGenerator from './pages/ExpenseReportGenerator';
import StatusReportGenerator from './pages/StatusReportGenerator';

// Error Pages
import NotFound from './pages/NotFound';

export default function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          {/* Main Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/ai" element={<AiLanding />} />

          {/* Document Routes */}
          {/* Contract */}
          <Route path="/contract" element={<ContractLanding />} />
          <Route path="/contract/generate" element={<ContractGenerator />} />

          {/* Invoice */}
          <Route path="/invoice" element={<InvoiceLanding />} />
          <Route path="/invoice/generate" element={<InvoiceGenerator />} />

          {/* Proposal */}
          <Route path="/proposal" element={<ProposalLanding />} />
          <Route path="/proposal/generate" element={<ProposalGenerator />} />

          {/* Receipt */}
          <Route path="/receipt" element={<ReceiptLanding />} />
          <Route path="/receipt/generate" element={<ReceiptGenerator />} />

          {/* Quote */}
          <Route path="/quote" element={<QuoteLanding />} />
          <Route path="/quote/generate" element={<QuoteGenerator />} />

          {/* Statement of Work */}
          <Route path="/sow" element={<SowLanding />} />
          <Route path="/sow/generate" element={<SowGenerator />} />

          {/* Project Brief */}
          <Route path="/brief" element={<BriefLanding />} />
          <Route path="/brief/generate" element={<BriefGenerator />} />

          {/* NDA */}
          <Route path="/nda" element={<NdaLanding />} />
          <Route path="/nda/generate" element={<NdaGenerator />} />

          {/* Timesheet */}
          <Route path="/timesheet" element={<TimeSheetLanding />} />
          <Route path="/timesheet/generate" element={<TimeSheetGenerator />} />

          {/* Expense Report */}
          <Route path="/expense" element={<ExpenseReportLanding />} />
          <Route path="/expense/generate" element={<ExpenseReportGenerator />} />

          {/* Status Report */}
          <Route path="/status" element={<StatusReportLanding />} />
          <Route path="/status/generate" element={<StatusReportGenerator />} />

          {/* 404 Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}