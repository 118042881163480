import React, { useState } from 'react';
import { ContractData } from '../types';
import { motion, AnimatePresence } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { FileDownload, RemoveRedEye } from '@mui/icons-material';

interface ContractPreviewProps {
  contractData: ContractData;
}

const ContractPreview: React.FC<ContractPreviewProps> = ({ contractData }) => {
  const [showFullPreview, setShowFullPreview] = useState(false);
  
  const {
    clientName,
    freelancerName,
    projectScope,
    startDate,
    endDate,
    deliverables,
    paymentAmount,
    paymentSchedule,
  } = contractData;

  const generateFullContract = () => {
    return `
      <div class="contract-full space-y-8 px-8 py-6 text-sm">
        <div class="text-center mb-8">
          <h2 class="text-2xl font-bold text-gray-900 mb-2">FREELANCE SERVICES AGREEMENT</h2>
          <p class="text-gray-600">Generated on ${new Date().toLocaleDateString()}</p>
        </div>

        <div class="space-y-6">
          <p>This Freelance Services Agreement (the "Agreement") is entered into as of ${new Date().toLocaleDateString()} by and between:</p>
          
          <p><strong>${clientName}</strong> (hereinafter referred to as the "Client"), and</p>
          <p><strong>${freelancerName}</strong> (hereinafter referred to as the "Freelancer").</p>

          <div class="my-6">
            <h3 class="font-bold mb-2">1. SCOPE OF SERVICES</h3>
            <p>The Freelancer agrees to provide the following services to the Client:</p>
            <div class="ml-4 mt-2">${projectScope}</div>
          </div>

          <div class="my-6">
            <h3 class="font-bold mb-2">2. PROJECT TIMELINE</h3>
            <p>The services shall commence on ${startDate} and shall continue until ${endDate}, unless terminated earlier in accordance with this Agreement.</p>
          </div>

          <div class="my-6">
            <h3 class="font-bold mb-2">3. DELIVERABLES</h3>
            <p>The Freelancer shall provide the following deliverables:</p>
            <div class="ml-4 mt-2">${deliverables}</div>
          </div>

          <div class="my-6">
            <h3 class="font-bold mb-2">4. COMPENSATION</h3>
            <p>4.1 The Client agrees to pay the Freelancer a total sum of $${paymentAmount} for the services rendered.</p>
            <p>4.2 Payment Schedule:</p>
            <div class="ml-4 mt-2">${paymentSchedule}</div>
          </div>

          <div class="my-6">
            <h3 class="font-bold mb-2">5. INTELLECTUAL PROPERTY RIGHTS</h3>
            <p>5.1 Upon receipt of full payment, the Freelancer assigns to the Client all intellectual property rights in the deliverables, including but not limited to copyrights, patents, and trade secrets.</p>
            <p>5.2 The Freelancer warrants that the deliverables will be original work and will not infringe upon the rights of any third party.</p>
          </div>

          <div class="my-6">
            <h3 class="font-bold mb-2">6. CONFIDENTIALITY</h3>
            <p>6.1 The Freelancer agrees to maintain the confidentiality of any proprietary or sensitive information received from the Client during the course of this engagement.</p>
            <p>6.2 This confidentiality obligation shall survive the termination of this Agreement.</p>
          </div>

          <div class="my-6">
            <h3 class="font-bold mb-2">7. INDEPENDENT CONTRACTOR STATUS</h3>
            <p>7.1 The Freelancer is an independent contractor and not an employee of the Client.</p>
            <p>7.2 The Freelancer shall be responsible for all taxes, insurance, and other obligations related to their self-employed status.</p>
          </div>

          <div class="my-6">
            <h3 class="font-bold mb-2">8. TERMINATION</h3>
            <p>8.1 Either party may terminate this Agreement with 14 days written notice.</p>
            <p>8.2 Upon termination, the Client shall pay for all services rendered up to the termination date.</p>
          </div>

          <div class="my-6">
            <h3 class="font-bold mb-2">9. GOVERNING LAW</h3>
            <p>This Agreement shall be governed by and construed in accordance with the laws of the jurisdiction where the Client is located.</p>
          </div>

          <div class="my-6">
            <h3 class="font-bold mb-2">10. ENTIRE AGREEMENT</h3>
            <p>This Agreement constitutes the entire understanding between the parties and supersedes all prior agreements, whether oral or written.</p>
          </div>

          <div class="mt-12 mb-8">
            <p class="font-bold mb-6">IN WITNESS WHEREOF, the parties have executed this Agreement as of the date first above written.</p>
            
            <div class="grid grid-cols-2 gap-8 mt-8">
              <div>
                <p class="font-bold mb-8">CLIENT:</p>
                <div class="border-b border-gray-900 w-48"></div>
                <p class="mt-2">${clientName}</p>
                <p class="mt-1">Date: _________________</p>
              </div>
              <div>
                <p class="font-bold mb-8">FREELANCER:</p>
                <div class="border-b border-gray-900 w-48"></div>
                <p class="mt-2">${freelancerName}</p>
                <p class="mt-1">Date: _________________</p>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-8 pt-8 border-t">
          <div class="grid grid-cols-2 gap-8">
            <div>
              <p class="text-sm text-gray-600 mb-8">Client Signature</p>
              <div class="border-b border-gray-900 w-48"></div>
              <p class="text-sm text-gray-600 mt-2">${clientName}</p>
              <p class="text-sm text-gray-600 mt-1">Date: _________________</p>
            </div>
            <div>
              <p class="text-sm text-gray-600 mb-8">Freelancer Signature</p>
              <div class="border-b border-gray-900 w-48"></div>
              <p class="text-sm text-gray-600 mt-2">${freelancerName}</p>
              <p class="text-sm text-gray-600 mt-1">Date: _________________</p>
            </div>
          </div>
        </div>

        <div class="mt-16 pt-8 border-t text-center">
          <div class="flex items-center justify-center gap-2 text-slate-400">
            <div class="flex h-4 w-4 items-end justify-center rounded-sm bg-slate-800 p-[4px]">
              <div class="relative flex items-end gap-[1px]">
                <div class="h-[6px] w-[2px] rounded-full bg-white"></div>
                <div class="h-[8px] w-[2px] -translate-y-[1px] rounded-full bg-white"></div>
              </div>
            </div>
            <span class="text-sm">Generated with Lipana</span>
          </div>
        </div>
      </div>
    `;
  };

  const handleDownloadPDF = async () => {
    const html2pdf = (await import('html2pdf.js')).default;
    
    // Create a temporary container for the full contract
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = generateFullContract();
    document.body.appendChild(tempContainer);
    
    const opt = {
      margin: 1,
      filename: 'freelance-contract.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    
    // Generate PDF from the temporary container
    await html2pdf().set(opt).from(tempContainer).save();
    
    // Remove the temporary container
    document.body.removeChild(tempContainer);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8"
    >
      <div className="flex justify-end mb-6 gap-3">
        <button
          onClick={() => setShowFullPreview(true)}
          className="flex items-center gap-2 px-4 py-2 bg-slate-800 text-white rounded-lg hover:bg-slate-700 transition-colors"
        >
          <RemoveRedEye className="w-4 h-4" />
          Preview Full Contract
        </button>
        <button
          onClick={handleDownloadPDF}
          className="flex items-center gap-2 px-4 py-2 bg-slate-800 text-white rounded-lg hover:bg-slate-700 transition-colors"
        >
          <FileDownload className="w-4 h-4" />
          Download PDF
        </button>
      </div>

      <div id="contract-content" className="space-y-8">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Freelance Contract Agreement</h2>
          <p className="text-gray-600">Generated on {new Date().toLocaleDateString()}</p>
        </div>

        <div className="space-y-6">
          <section className="border-b pb-6">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Parties Involved</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-600">Client</p>
                <p className="font-medium text-gray-900">{clientName}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Freelancer</p>
                <p className="font-medium text-gray-900">{freelancerName}</p>
              </div>
            </div>
          </section>

          <section className="border-b pb-6">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Project Details</h3>
            <div className="space-y-4">
              <div>
                <p className="text-sm text-gray-600 mb-2">Project Scope</p>
                <ReactMarkdown className="prose text-gray-900">
                  {projectScope}
                </ReactMarkdown>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-600">Start Date</p>
                  <p className="font-medium text-gray-900">{startDate}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">End Date</p>
                  <p className="font-medium text-gray-900">{endDate}</p>
                </div>
              </div>
            </div>
          </section>

          <section className="border-b pb-6">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Deliverables</h3>
            <ReactMarkdown className="prose text-gray-900">
              {deliverables}
            </ReactMarkdown>
          </section>

          <section>
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Payment Terms</h3>
            <div className="space-y-4">
              <div>
                <p className="text-sm text-gray-600">Payment Amount</p>
                <p className="font-medium text-gray-900">${paymentAmount}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Payment Schedule</p>
                <ReactMarkdown className="prose text-gray-900">
                  {paymentSchedule}
                </ReactMarkdown>
              </div>
            </div>
          </section>
        </div>

        <div className="mt-8 pt-8 border-t">
          <div className="grid grid-cols-2 gap-8">
            <div>
              <p className="text-sm text-gray-600 mb-8">Client Signature</p>
              <div className="border-b border-gray-900 w-48"></div>
              <p className="text-sm text-gray-600 mt-2">{clientName}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600 mb-8">Freelancer Signature</p>
              <div className="border-b border-gray-900 w-48"></div>
              <p className="text-sm text-gray-600 mt-2">{freelancerName}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Full Contract Preview Modal */}
      <AnimatePresence>
        {showFullPreview && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black"
              onClick={() => setShowFullPreview(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="fixed inset-4 bg-white rounded-xl overflow-auto p-8 max-w-4xl mx-auto"
              style={{ top: '2%', height: '96%' }}
            >
              <div className="flex justify-end mb-4">
                <button
                  onClick={() => setShowFullPreview(false)}
                  className="text-gray-500 hover:text-gray-700 rounded-full p-2 hover:bg-gray-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div 
                className="contract-full space-y-8"
                dangerouslySetInnerHTML={{ __html: generateFullContract() }}
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ContractPreview;