import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>404 - Page Not Found | Lipana</title>
        <meta name="description" content="The page you're looking for doesn't exist." />
      </Helmet>

      <div className="min-h-screen">
        <Header />

        <main className="flex-grow flex items-center justify-center py-32">
          <div className="text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="space-y-6"
            >
              <h1 className="text-6xl font-bold text-slate-900">404</h1>
              <h2 className="text-2xl font-semibold text-slate-700">Page Not Found</h2>
              <p className="text-slate-600 max-w-md mx-auto">
                The page you're looking for doesn't exist or has been moved.
              </p>
              <div>
                <Link
                  to="/"
                  className="inline-flex items-center gap-2 px-6 py-3 bg-slate-800 text-white rounded-lg hover:bg-slate-700 transition-colors"
                >
                  <ArrowBack className="w-4 h-4" />
                  Back to Home
                </Link>
              </div>
            </motion.div>
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
} 