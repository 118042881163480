import React from 'react';
import { ExpenseReportData, ExpenseItem } from '../types/expense';
import { motion } from 'framer-motion';
import { Add, Delete, CloudUpload, Close } from '@mui/icons-material';

interface ExpenseReportFormProps {
  formData: ExpenseReportData;
  setFormData: React.Dispatch<React.SetStateAction<ExpenseReportData>>;
  onSubmit: (e: React.FormEvent) => void;
}

export default function ExpenseReportForm({ formData, setFormData, onSubmit }: ExpenseReportFormProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 500000) {
        alert('File size should be less than 500KB');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          logo: reader.result as string
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const removeLogo = () => {
    setFormData(prev => ({
      ...prev,
      logo: undefined
    }));
  };

  const handleReceiptUpload = async (index: number, file: File) => {
    if (file.size > 2000000) { // 2MB limit
      alert('Receipt file size should be less than 2MB');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const newExpenses = [...formData.expenses];
      newExpenses[index] = {
        ...newExpenses[index],
        receipt: reader.result as string
      };
      setFormData(prev => ({
        ...prev,
        expenses: newExpenses
      }));
    };
    reader.readAsDataURL(file);
  };

  const addExpense = () => {
    setFormData(prev => ({
      ...prev,
      expenses: [...prev.expenses, {
        date: new Date().toISOString().split('T')[0],
        category: '',
        description: '',
        amount: 0
      }]
    }));
  };

  const removeExpense = (index: number) => {
    setFormData(prev => {
      const newExpenses = prev.expenses.filter((_, i) => i !== index);
      const subtotal = newExpenses.reduce((sum, item) => sum + item.amount, 0);
      const tax = subtotal * 0.1; // 10% tax rate
      const total = subtotal + tax;

      return {
        ...prev,
        expenses: newExpenses,
        subtotal,
        tax,
        total
      };
    });
  };

  const updateExpense = (index: number, field: keyof ExpenseItem, value: string | number) => {
    setFormData(prev => {
      const newExpenses = [...prev.expenses];
      newExpenses[index] = {
        ...newExpenses[index],
        [field]: value
      };

      const subtotal = newExpenses.reduce((sum, item) => sum + item.amount, 0);
      const tax = subtotal * 0.1; // 10% tax rate
      const total = subtotal + tax;

      return {
        ...prev,
        expenses: newExpenses,
        subtotal,
        tax,
        total
      };
    });
  };

  const expenseCategories = [
    'Travel',
    'Meals',
    'Office Supplies',
    'Software',
    'Hardware',
    'Professional Services',
    'Marketing',
    'Training',
    'Other'
  ];

  return (
    <form onSubmit={onSubmit} className="space-y-8">
      {/* Basic Information */}
      <div className="space-y-6">
        <h3 className="text-lg font-semibold text-slate-800">Report Information</h3>
        
        {/* Logo Upload */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Company Logo (optional)
          </label>
          {formData.logo ? (
            <div className="relative w-40 h-20 mb-2">
              <img
                src={formData.logo}
                alt="Company logo"
                className="w-full h-full object-contain"
              />
              <button
                type="button"
                onClick={removeLogo}
                className="absolute -top-2 -right-2 p-1 bg-red-100 rounded-full text-red-500 hover:bg-red-200"
              >
                <Close className="w-4 h-4" />
              </button>
            </div>
          ) : (
            <div className="flex items-center justify-center w-full">
              <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-slate-500 rounded-lg border-2 border-dashed border-slate-200 cursor-pointer hover:bg-slate-50">
                <CloudUpload className="w-8 h-8 mb-2" />
                <span className="text-sm">Upload logo (max 500KB)</span>
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handleLogoUpload}
                />
              </label>
            </div>
          )}
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Report Number
            </label>
            <input
              type="text"
              name="reportNumber"
              value={formData.reportNumber}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Date Created
            </label>
            <input
              type="date"
              name="dateCreated"
              value={formData.dateCreated}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Period From
            </label>
            <input
              type="date"
              name="dateFrom"
              value={formData.dateFrom}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Period To
            </label>
            <input
              type="date"
              name="dateTo"
              value={formData.dateTo}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>
      </div>

      {/* Employee Details */}
      <div className="space-y-6">
        <h3 className="text-lg font-semibold text-slate-800">Employee Details</h3>
        
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Employee Name
            </label>
            <input
              type="text"
              name="employeeName"
              value={formData.employeeName}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Employee ID
            </label>
            <input
              type="text"
              name="employeeId"
              value={formData.employeeId}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email
            </label>
            <input
              type="email"
              name="employeeEmail"
              value={formData.employeeEmail}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Department
            </label>
            <input
              type="text"
              name="department"
              value={formData.department}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Project Name (optional)
            </label>
            <input
              type="text"
              name="projectName"
              value={formData.projectName}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Client Name (optional)
            </label>
            <input
              type="text"
              name="clientName"
              value={formData.clientName}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            />
          </div>
        </div>
      </div>

      {/* Expenses */}
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold text-slate-800">Expenses</h3>
          <button
            type="button"
            onClick={addExpense}
            className="flex items-center gap-2 px-4 py-2 text-sm text-slate-800 hover:bg-slate-100 rounded-lg transition-colors"
          >
            <Add className="w-4 h-4" />
            Add Expense
          </button>
        </div>
        
        <div className="space-y-4">
          {formData.expenses.map((expense, index) => (
            <div key={index} className="grid grid-cols-12 gap-4 items-start">
              <div className="col-span-2">
                <input
                  type="date"
                  value={expense.date}
                  onChange={(e) => updateExpense(index, 'date', e.target.value)}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div className="col-span-2">
                <select
                  value={expense.category}
                  onChange={(e) => updateExpense(index, 'category', e.target.value)}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                >
                  <option value="">Select Category</option>
                  {expenseCategories.map(category => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
              </div>
              <div className="col-span-4">
                <input
                  type="text"
                  placeholder="Description"
                  value={expense.description}
                  onChange={(e) => updateExpense(index, 'description', e.target.value)}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div className="col-span-2">
                <input
                  type="number"
                  placeholder="Amount"
                  value={expense.amount}
                  onChange={(e) => updateExpense(index, 'amount', Number(e.target.value))}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div className="col-span-1">
                <label className="w-full flex flex-col items-center px-4 py-3 bg-white text-slate-500 rounded-lg border border-gray-300 cursor-pointer hover:bg-slate-50">
                  <CloudUpload className="w-4 h-4" />
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) handleReceiptUpload(index, file);
                    }}
                  />
                </label>
              </div>
              <div className="col-span-1">
                <button
                  type="button"
                  onClick={() => removeExpense(index)}
                  className="p-3 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
                >
                  <Delete className="w-4 h-4" />
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end">
          <div className="w-64 space-y-3">
            <div className="flex justify-between">
              <span className="text-gray-600">Subtotal</span>
              <span className="font-medium">${formData.subtotal.toFixed(2)}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Tax (10%)</span>
              <span className="font-medium">${formData.tax.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-lg font-semibold border-t border-gray-200 pt-2">
              <span>Total</span>
              <span>${formData.total.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Payment Details */}
      <div className="space-y-6">
        <h3 className="text-lg font-semibold text-slate-800">Payment Details</h3>
        
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Payment Method
            </label>
            <select
              name="paymentMethod"
              value={formData.paymentMethod}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            >
              <option value="Bank Transfer">Bank Transfer</option>
              <option value="Credit Card">Credit Card</option>
              <option value="Cash">Cash</option>
              <option value="Check">Check</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Reimbursement Method
            </label>
            <select
              name="reimbursementMethod"
              value={formData.reimbursementMethod}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
            >
              <option value="Direct Deposit">Direct Deposit</option>
              <option value="Check">Check</option>
              <option value="Cash">Cash</option>
            </select>
          </div>
        </div>

        {formData.reimbursementMethod === 'Direct Deposit' && (
          <div className="space-y-4">
            <h4 className="font-medium text-slate-800">Bank Details</h4>
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Account Name
                </label>
                <input
                  type="text"
                  value={formData.bankDetails?.accountName}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    bankDetails: {
                      ...prev.bankDetails!,
                      accountName: e.target.value
                    }
                  }))}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Account Number
                </label>
                <input
                  type="text"
                  value={formData.bankDetails?.accountNumber}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    bankDetails: {
                      ...prev.bankDetails!,
                      accountNumber: e.target.value
                    }
                  }))}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Bank Name
                </label>
                <input
                  type="text"
                  value={formData.bankDetails?.bankName}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    bankDetails: {
                      ...prev.bankDetails!,
                      bankName: e.target.value
                    }
                  }))}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Swift Code (optional)
                </label>
                <input
                  type="text"
                  value={formData.bankDetails?.swiftCode}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    bankDetails: {
                      ...prev.bankDetails!,
                      swiftCode: e.target.value
                    }
                  }))}
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Comments */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Comments
        </label>
        <textarea
          name="comments"
          value={formData.comments}
          onChange={handleChange}
          rows={4}
          className="w-full rounded-lg border border-gray-300 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-slate-800 focus:border-transparent"
          placeholder="Any additional notes or comments..."
        />
      </div>

      <div className="pt-6">
        <button
          type="submit"
          className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 transition-all duration-200"
        >
          Preview Expense Report
        </button>
      </div>
    </form>
  );
} 